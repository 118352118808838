import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';
import '../styles/OCR.css';

const OCR = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [extractedData, setExtractedData] = useState({});
  const [loading, setLoading] = useState(false);
  const webcamRef = useRef(null);

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setExtractedData({}); // Clear previous data
  };

  const processImage = async () => {
    if (!imageSrc) return;
    setLoading(true);
    try {
      // Example: Define ROIs based on expected license layout
      const licenseNumberROI = { x: 100, y: 50, width: 300, height: 50 };
      const nameROI = { x: 100, y: 150, width: 300, height: 50 };
      const expiryDateROI = { x: 100, y: 250, width: 300, height: 50 };

      const extractedData = {};

      // Function to process specific ROIs
      const extractFromROI = async (roi) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = imageSrc;

        return new Promise((resolve) => {
          img.onload = async () => {
            canvas.width = roi.width;
            canvas.height = roi.height;
            ctx.drawImage(img, roi.x, roi.y, roi.width, roi.height, 0, 0, roi.width, roi.height);

            const roiSrc = canvas.toDataURL();
            const result = await Tesseract.recognize(
              roiSrc,
              'eng+heb', // Adjust for the languages used in the license
              {
                logger: (m) => console.log(m),
              }
            );
            resolve(result.data.text.trim());
          };
        });
      };

      extractedData.licenseNumber = await extractFromROI(licenseNumberROI);
      extractedData.name = await extractFromROI(nameROI);
      extractedData.expiryDate = await extractFromROI(expiryDateROI);

      setExtractedData(extractedData);
    } catch (error) {
      console.error('Error during OCR processing:', error);
      setExtractedData({ error: 'Error extracting text.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ocr-container">
      <h2>Scan Your License</h2>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          width: 1280,
          height: 720,
          facingMode: 'environment', // Use rear camera for mobile
        }}
        className="webcam"
      />
      <button onClick={captureImage} className="capture-button">Capture Image</button>
      
      {imageSrc && (
        <div className="preview-container">
          <h3>Preview:</h3>
          <img src={imageSrc} alt="Captured License" className="preview-image" />
          <button onClick={processImage} className="process-button">
            {loading ? 'Processing...' : 'Scan License'}
          </button>
        </div>
      )}

      {extractedData && (
        <div className="results-container">
          <h3>Extracted Details:</h3>
          {extractedData.error ? (
            <p>{extractedData.error}</p>
          ) : (
            <ul>
              <li>License Number: {extractedData.licenseNumber}</li>
              <li>Name: {extractedData.name}</li>
              <li>Expiry Date: {extractedData.expiryDate}</li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default OCR;

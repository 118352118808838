import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebase';
import sendEmail from '../components/Email';
import '../styles/Management.css';
import text from '../text';
import { useLanguage } from '../contexts/LanguageContext';
import OpeningHoursUpdater from './OpeningHoursUpdater'; 
import ManagePistols from './ManagePistols'; // Import the ManagePistols component

const Management = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [eventTitle, setEventTitle] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventMedia, setEventMedia] = useState('');
  const [activeSection, setActiveSection] = useState(null); 
  const [totalEntries, setTotalEntries] = useState(0);
  const [monthlyEntries, setMonthlyEntries] = useState(0);
  const [youtubeVisits, setYoutubeVisits] = useState(0);
  const [youtubePlays, setYoutubePlays] = useState(0);
  const { language } = useLanguage();

  const unsubscribeLink = 'https://shailevigun.com/unsubscribe';

  useEffect(() => {
    const fetchSubscribers = async () => {
      const querySnapshot = await getDocs(collection(db, 'subscribers'));
      const subs = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        subs.push({
          email: data.email,
          mobile: data.mobile,
          name: data.name,
        });
      });
      setSubscribers(subs);
    };

    const fetchTotalEntries = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'entries'));
        setTotalEntries(querySnapshot.size);
      } catch (error) {
        console.error('Error fetching total entries:', error);
      }
    };

    const fetchMonthlyEntries = async () => {
      try {
        const startOfMonthDate = new Date();
        startOfMonthDate.setDate(1);
        const q = query(collection(db, 'entries'), where('date', '>=', startOfMonthDate));
        const querySnapshot = await getDocs(q);
        setMonthlyEntries(querySnapshot.size);
      } catch (error) {
        console.error('Error fetching monthly entries:', error);
      }
    };

    const fetchYoutubeAnalytics = async () => {
      try {
        const youtubeVisitSnapshot = await getDocs(query(collection(db, 'analytics'), where('event', '==', 'youtube_channel_visited')));
        const youtubeVisitCount = youtubeVisitSnapshot.docs.reduce((total, doc) => total + parseInt(doc.data().counter, 10), 0);
        setYoutubeVisits(youtubeVisitCount);

        const youtubePlaySnapshot = await getDocs(query(collection(db, 'analytics'), where('event', '==', 'onsite_youtube_played')));
        const youtubePlayCount = youtubePlaySnapshot.docs.reduce((total, doc) => total + parseInt(doc.data().counter, 10), 0);
        setYoutubePlays(youtubePlayCount);
      } catch (error) {
        console.error('Error fetching YouTube analytics:', error);
      }
    };

    fetchSubscribers();
    fetchTotalEntries();
    fetchMonthlyEntries();
    fetchYoutubeAnalytics();
  }, []);

  const handleSendEmails = () => {
    const emailParams = {
      event_title: eventTitle,
      event_description: eventDescription,
      event_media: eventMedia,
      hello_subscribers: text[language].helloSubscribers,
      best_wishes: text[language].bestWishes,
      ShaiLevi_signture: text[language].signature,
      unsubscribe: text[language].unsubscribeText,
      unsubscribe_link: unsubscribeLink,
      unsubscribe_link_title: text[language].unsubscribeLinkTitle,
      to_email: "orilevi026@gmail.com",
    };

    subscribers.forEach((subscriber) => {
      emailParams.to_email = subscriber.email;
      sendEmail(emailParams);
    });
  };

  return (
    <div className="management">
      <div className="control-panel">
        <div className="panel-item">
          <h3>כניסות לאתר</h3>
          <p>{totalEntries}</p>
        </div>
        <div className="panel-item">
          <h3>כניסות חודשיות</h3>
          <p>{monthlyEntries}</p>
        </div>
        <div className="panel-item">
          <h3>עוקבים</h3>
          <p>{subscribers.length}</p>
        </div>
        <div className="panel-item">
          <h3>מעבר מהאתר לYoutube</h3>
          <p>{youtubeVisits}</p>
        </div>
        <div className="panel-item">
          <h3>צפיות באתר Youtube</h3>
          <p>{youtubePlays}</p>
        </div>
      </div>
      <div className="actions">
        <button onClick={() => setActiveSection(activeSection === 'subscribers' ? null : 'subscribers')}>
          {activeSection === 'subscribers' ? 'הסתר עוקבים' : 'הצג עוקבים'}
        </button>
        <button onClick={() => setActiveSection(activeSection === 'hoursUpdater' ? null : 'hoursUpdater')}>
          {activeSection === 'hoursUpdater' ? 'סגור' : 'עדכן שעות'}
        </button>
        <button onClick={() => setActiveSection(activeSection === 'promoteEvent' ? null : 'promoteEvent')}>
          {activeSection === 'promoteEvent' ? 'סגור' : 'פרסם אירוע'}
        </button>
        <button onClick={() => setActiveSection(activeSection === 'managePistols' ? null : 'managePistols')}>
          {activeSection === 'managePistols' ? 'סגור' : 'ניהול אקדחים משומשים'}
        </button>
      </div>
      {activeSection === 'subscribers' && (
        <div className="subscribers-list">
          <h3>רשימת עוקבים מהאתר</h3>
          <table>
            <thead>
              <tr>
                <th>שם</th>
                <th>נייד</th>
                <th>אימייל</th>
              </tr>
            </thead>
            <tbody>
              {subscribers.map((subscriber, index) => (
                <tr key={index}>
                  <td>{subscriber.name}</td>
                  <td>{subscriber.mobile}</td>
                  <td>{subscriber.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {activeSection === 'promoteEvent' && (
        <div className="promote-event">
          <h3>פרסם אירוע</h3>
          <input
            type="text"
            placeholder="שם האירוע"
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
          />
          <textarea
            placeholder="תיאור האירוע"
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
          />
          <input
            type="text"
            placeholder="*אופציונלי - צרף קישור לסרטון YouTube"
            value={eventMedia}
            onChange={(e) => setEventMedia(e.target.value)}
          />
          <button onClick={handleSendEmails}>שלח אימייל לכל העוקבים</button>
        </div>
      )}
      {activeSection === 'hoursUpdater' && (
        <div className="hours-updater">
          <OpeningHoursUpdater />
        </div>
      )}
      {activeSection === 'managePistols' && (
        <div className="manage-pistols">
          <ManagePistols />
        </div>
      )}
    </div>
  );
};

export default Management;

import React, { useState, useEffect } from 'react';
import { updateModelDetails, getUserRole, db } from './firebase';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { doc, getDoc } from 'firebase/firestore';
import text from '../text';
import glockLogo from '../images/glock.svg';
import sigSauerLogo from '../images/sig-sauer.svg';
import springfieldLogo from '../images/springfield.svg';
import mossbergLogo from '../images/mossberg.svg';
import iwiLogo from '../images/iwi.svg';
import shadowSystemsLogo from '../images/shadow-systems.png';
import czLogo from '../images/cz.png';
import swLogo from '../images/sw.png';
import '../styles/OurGuns.css';
import { Helmet } from 'react-helmet';


const OurGuns = ({ user }) => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [modelDetails, setModelDetails] = useState(null); // Store the details separately
  const [isManager, setIsManager] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editDetails, setEditDetails] = useState({});

  const importAll = (r) => {
    return r.keys().map((item, index) => ({
      name: item.replace('./', '').replace(/\.[^/.]+$/, "").replaceAll('_', ' '),
      src: r(item),
    }));
  };

  const manufacturers = [
    {
      name: text[language].glock,
      logo: glockLogo,
      invert: true,
      models: importAll(require.context('../images/glocks', false, /\.(png|jpe?g|svg)$/)),
    },
    {
      name: text[language].sigSauer,
      logo: sigSauerLogo,
      invert: false,
      models: importAll(require.context('../images/sig-sauer', false, /\.(png|jpe?g|svg)$/)),
    },
    {
      name: text[language].springfield,
      logo: springfieldLogo,
      invert: true,
      models: importAll(require.context('../images/springfield', false, /\.(png|jpe?g|svg|webp)$/)),
    },
    {
      name: text[language].mossberg,
      logo: mossbergLogo,
      invert: false,
      models: importAll(require.context('../images/mossberg', false, /\.(png|jpe?g|svg)$/)),
    },
    {
      name: text[language].iwi,
      logo: iwiLogo,
      invert: false,
      models: importAll(require.context('../images/iwi', false, /\.(png|jpe?g|svg)$/)),
    },
    {
      name: text[language].shadowSystems,
      logo: shadowSystemsLogo,
      invert: false,
      models: importAll(require.context('../images/shadow-systems', false, /\.(png|jpe?g|svg)$/)),
    },
    {
      name: text[language].cz,
      logo: czLogo,
      invert: false,
      models: importAll(require.context('../images/cz', false, /\.(png|jpe?g|svg)$/)),
    },
    {
      name: text[language].sw,
      logo: swLogo,
      invert: true,
      models: importAll(require.context('../images/sw', false, /\.(png|jpe?g|svg)$/)),
    },
  ];

  useEffect(() => {
    const fetchUserRole = async () => {
      const role = await getUserRole(user);
      setIsManager(role === 'manager');
    };

    fetchUserRole();
  }, [user]);

  useEffect(() => {
    if (selectedModel) {
      const fetchModelDetails = async (modelId) => {
        const modelDoc = await getDoc(doc(db, 'models', modelId));
        if (modelDoc.exists()) {
          setModelDetails(modelDoc.data()); // Set the details in a different state
        } else {
          setModelDetails({
            category: 'N/A',
            caliber: 'N/A',
            weight: 'N/A',
            description: 'N/A',
          });
        }
      };

      fetchModelDetails(selectedModel.name);
    } else {
      setModelDetails(null); // Reset model details when no model is selected
    }
  }, [selectedModel]);

  const handleManufacturerClick = (manufacturer) => {
    setSelectedManufacturer(
      selectedManufacturer === manufacturer ? null : manufacturer
    );
    setSelectedModel(null);
  };

  const handleModelClick = (model) => {
    setSelectedModel(model);
    setEditMode(false); // Exit edit mode when selecting a different model
    // Scroll down about 20% of the page height
    const scrollHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    const scrollDistance = scrollHeight ;
    const position = scrollHeight - windowHeight + scrollDistance;
    window.scrollTo({
      top: position,
      behavior: 'smooth',
    });
  };

  const handleShowUsedGuns = () => {
    navigate('/usedPistols');
  };

  const handleEdit = () => {
    setEditMode(true);
    setEditDetails({
      name: selectedModel.name,
      category: modelDetails?.category || 'קטגוריה',
      caliber: modelDetails?.caliber || 'קוטר קליע',
      weight: modelDetails?.weight || 'משקל',
      description: modelDetails?.description || 'תיאור',
      videos: modelDetails?.videos || [],
    });
  };

  const handleSave = async () => {
    // Validate video links
    const validVideos = editDetails.videos.filter((video) => {
      // Check if the video link is not empty and is a valid YouTube URL
      if (!video) {
        alert('סרטון ריק הוסר מהרשימה');
        return false;
      }
  
      try {
        const url = new URL(video);
        if (url.hostname !== 'www.youtube.com' && url.hostname !== 'youtube.com' && url.hostname !== 'youtu.be') {
          alert('אחד הקישורים אינו קישור YouTube והוסר מהרשימה');
          return false;
        }
      } catch (e) {
        alert('אחד הקישורים אינו קישור YouTube והוסר מהרשימה');
        return false;
      }
  
      return true; // If the link is valid
    });
  
    // Update editDetails with the validated video links
    setEditDetails({ ...editDetails, videos: validVideos });
  
    // Save the updated details to Firestore
    await updateModelDetails(selectedModel.name, { ...editDetails, videos: validVideos });
    setModelDetails({ ...editDetails, videos: validVideos }); // Update the local details
    setEditMode(false);
  };
  

  return (
    <div className="our-guns">
      <Helmet>
        <title>מטווח שי לוי - האקדחים שלנו</title>
        <meta name="description" content="מטווח שי לוי - קרני שומרון, ביקורות אקדחים, אימונים, חידוש רישיון ועוד" />
        <meta name="keywords" content=", נרתיק לאקדח, זיג, Sig Sauer, הלקט , אקדח חדש, אקדח משומש דיקת תיקנות נשק, רענון תקופתי, מטווח , מטווח שי לוי , הכשרה ראשונית , רישיון לאקדח, גלוק, Glock, מאבטחים, חידוש רישיון, קרני שומרון" />
      </Helmet>
      <button className="used-guns-button" onClick={handleShowUsedGuns}>
        אקדחים משומשים
      </button>
      <button className="weapon-reviews-button" onClick={() => navigate('/weapon-reviews')}>ביקורות נשק</button>
      <h2>{text[language].ourGunsTitle}</h2>
      <p>{text[language].ourGunsErrors}</p>
      <div className="manufacturers-row">
        {manufacturers.map((manufacturer, index) => (
          <div
            key={index}
            className={`manufacturer-card ${selectedManufacturer === manufacturer.name ? 'selected' : ''}`}
            onClick={() => handleManufacturerClick(manufacturer.name)}
          >
            <div className="gun-card">
              <img
                src={manufacturer.logo}
                alt={manufacturer.name}
                className={manufacturer.invert ? 'color-invert' : ''}
              />
              <p>{manufacturer.name}</p>
            </div>
          </div>
        ))}
      </div>
      {selectedManufacturer && (
        <div className="models-row">
          {manufacturers
            .find(manufacturer => manufacturer.name === selectedManufacturer)
            .models.map((model, idx) => (
              <div
                className={`model-card ${selectedModel && selectedModel.name === model.name ? 'selected' : ''}`}
                key={idx}
                onClick={() => handleModelClick(model)}
              >
                <img src={model.src} alt={model.name} />
                <p>{model.name}</p>
              </div>
            ))}
        </div>
      )}
      {selectedModel && modelDetails && (
        <div className="model-detail-card">
          {isManager ? (
                  <button className="edit-button" onClick={handleEdit}>שלום {user.displayName} - ערוך פרטים</button>
                ) : null}
          {editMode ? (
            <div className="model-edit-form">
              <h3>{selectedManufacturer} - {editDetails.name}</h3>
              <div className="form-group">
                <label>שם</label>
                <input
                  type="text"
                  value={editDetails.name}
                  onChange={(e) => setEditDetails({ ...editDetails, name: e.target.value })}
                  placeholder="Name"
                />
                <label>קטגוריה</label>
                <input
                  type="text"
                  value={editDetails.category}
                  onChange={(e) => setEditDetails({ ...editDetails, category: e.target.value })}
                  placeholder="Category"
                />
              </div>
              <div className="form-group">
                <label>קוטר קליע</label>
                <input
                  type="text"
                  value={editDetails.caliber}
                  onChange={(e) => setEditDetails({ ...editDetails, caliber: e.target.value })}
                  placeholder="Caliber"
                />
              </div>
              <div className="form-group">
                <label>משקל</label>
                <input
                  type="text"
                  value={editDetails.weight}
                  onChange={(e) => setEditDetails({ ...editDetails, weight: e.target.value })}
                  placeholder="Weight"
                />
              </div>
              <div className="form-group">
                <label>תיאור</label>
                <textarea
                  value={editDetails.description}
                  onChange={(e) => setEditDetails({ ...editDetails, description: e.target.value })}
                  placeholder="Description"
                />
              </div>
              
              <div className="form-group">
                <label>הוספת קישור ל-YouTube</label>
                {editDetails.videos?.map((video, index) => (
                  <div key={index} className="video-input-group">
                    <input
                      type="text"
                      value={video}
                      onChange={(e) => {
                        const newVideos = [...editDetails.videos];
                        newVideos[index] = e.target.value;
                        setEditDetails({ ...editDetails, videos: newVideos });
                      }}
                      placeholder="YouTube Video URL"
                    />
                    <button
                      type="button"
                      className="remove-video-button"
                      onClick={() => {
                        const newVideos = editDetails.videos.filter((_, i) => i !== index);
                        setEditDetails({ ...editDetails, videos: newVideos });
                      }}
                    >
                      הסר סרטון
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="add-video-button"
                  onClick={() => setEditDetails({ ...editDetails, videos: [...(editDetails.videos || []), ''] })}
                >
                  הוסף סרטון
                </button>
              </div>

              <button className="save-button" onClick={handleSave}>שמור</button>
            </div>
          ) : (
            <div className="model-detail-content">
              <div className="model-detail-image-section">
                <img src={selectedModel.src} alt={selectedModel.name} className="model-detail-image" />
              </div>
              <div className="model-detail-info">
                <h3>{modelDetails.name ? modelDetails.name :selectedManufacturer + ' ' +selectedModel.name}</h3>
                <p>קטגוריה: {modelDetails.category}</p>
                <p>קוטר קליע: {modelDetails.caliber}</p>
                <p>משקל: {modelDetails.weight}</p>
                <p>תיאור: {modelDetails.description}</p>           
              </div>
              {!editMode && modelDetails?.videos?.length > 0 && (
                  <div className="videos">
                    {modelDetails.videos.map((video, index) => (
                      <div key={index} className="video">
                        <iframe
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${new URL(video).searchParams.get('v')}`}
                          title={`YouTube video player ${index + 1}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OurGuns;

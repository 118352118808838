const text = {
  en: {
    welcome: 'Welcome',
    loading: 'Loading...',
    gunRangeTitle: 'Shooting Range Shai Levi, Karnei Shomron',
    renewalHoursTitle: 'Training Hours - Renewal/New License',
    renewalHoursContentWeekdays: "Sunday to Thursday: 9:00, 14:00, 17:00",
    renewalHoursContentWeekends: "Fridays and holidays: 8:00, 12:00",
    firstTrainingHoursTitle: 'Initial Training Hours',
    firstTrainingHoursWeekdays: "Sunday to Thursday: 9:00, 14:00",
    firstTrainingHoursWeekends: "Fridays and holidays: 8:00",
    officeHoursTitle: 'Office Hours',
    officeHoursContentWeekdays: "Sunday to Thursday: 9:00 to 17:30",
    officeHoursContentWeekends: "Fridays and holidays: 8:00 to 12:00",
    arrival: 'No appointment needed – just be in time',
    welcomeMessage: 'Welcome to our shooting range!',
    aboutUsTitle: 'About Us',
    aboutUsDescription: 'Shai Levi Shoothing Range in Karnei Shomron',
    aboutUsContent: 'The Shai Levi Shooting Range in '+
    'Karnei Shomron was established in 2017 by Shay Levy, a firearms and Krav Maga instructor with extensive experience in the field. '+
    'The range was built with the vision of providing high-quality and safe training for firearms enthusiasts and those seeking to '+
    'deepen their knowledge and skills in responsible and proper firearm use. The facility specializes in a wide range of training sessions, '+
    'from basic courses to advanced firearms and Krav Maga training, adhering to high standards of professionalism and safety. The range offers a '+
    'professional and welcoming environment for anyone interested in learning and improving their skills in these areas, with personal guidance and '+
    'mentoring by a skilled and experienced team.',
    newLicenseTitle: 'New License',
    newLicenseIntroduction: 'So you want to get a gun license - you have come to the right place, but first:',
    conditionalApproval: 'I have conditional approval',
    eligibleWithoutApproval: 'I am eligible to carry a firearm but do not have the required documents',
    requiredDocsIntro: 'I am eligible to carry a firearm and have the required documents',
    gotDocs: 'I have the documents',
    backToStart: 'Back to start',
    requiredDocsTitle: 'List of documents you need to obtain to get a license:',
    healthDeclaration: 'Signed health declaration from your family doctor',
    downloadForm: 'Download form',
    applicationDocsSubtitle: 'For the application form, you need to obtain documents for the criteria under which you are eligible to obtain a gun license:',
    residencyCertificate: 'Certificate of residence in an eligible settlement',
    contactMunicipality: 'Contact your local council/municipality for this document',
    or: 'or',
    securityForcesService: 'Document confirming service in security forces/IDF',
    idfWebsite: 'IDF Approvals Website',
    applicationOnlineIntro: 'Have you obtained the documents? You can now fill out the application online:',
    applicationForm: 'Application form for carrying a firearm',
    fillOnline: 'Fill out the form online',
    passportPhoto: 'Passport photo',
    idAndAppendix: 'ID card and appendix',
    conditionalApprovalSent: 'After approval of the application, a "conditional approval" form will be sent to your email, which you will use to register for initial training.',
    licenseIssuanceStepsTitle: 'Steps for issuing the license:',
    step1: 'Receiving a conditional approval form from the Ministry of National Security',
    step2: 'Arriving at the initial training - no appointment needed',
    step3: 'Purchasing a gun - we will show you our guns and answer all your questions',
    step4: 'Receiving a temporary license (according to the working hours of the Ministry of National Security)',
    step5: 'Collecting the gun',
    step6: 'Receiving a permanent license',
    whenToCome: 'When to come?',
    conditionalApprovalAcquired: 'Aquired conditional approval for obtaining a gun license',
    trainingHoursTitle: 'Training hours:',
    trainingWeekdays: 'Sunday - Thursday: Morning training at 09:00, Afternoon training at 14:00',
    trainingWeekends: 'Friday: Morning training at 08:00 - usually very busy',
    noReservationRequired: 'No reservation needed',
    trainingCost: 'Training cost 500 NIS - conditional on purchasing a gun, without purchasing a gun - training cost is 650 NIS',
    gunCost: 'Gun cost starts from 3500 NIS',
    initialTrainingTitle: 'Initial training:',
    initialTrainingDetails: 'The training lasts about 5 hours and includes a theoretical lesson and exam, learning about different types of firearms, advice and guidance on choosing a gun, dry training, and live fire training.',
    trainingRequirements: 'You must wear long pants and closed shoes',
    contactTitle: 'Contact Us',
    footerAddress: 'Shooting Range Shai Levi - Karnei Shomron',
    address: 'Karnei Shomron, Rahavam Zeavi 1',
    footerPhone: '058-591-4713',
    joinCommunity: 'Join our community',
    loginButton: 'Login',
    loginPromotion: 'Login and save time in future registrations',
    ourGunsTitle: 'Our Guns',
    weaponReviewsTitle: 'Weapon Reviews',
    weaponReviewsDescription: 'Instructional videos and Pistols reviews',
    glock: 'Glock',
    sigSauer: 'Sig Sauer',
    springfield: 'Springfield',
    mossberg: 'Mossberg',
    iwi: 'IWI',
    shadowSystems: 'Shadow Systems',
    cz: 'CZ',
    sw: 'Smith & Wesson',
    newestVideos: 'Newest Videos',
    topViewedVideos: 'Top Viewed Videos',
    youtubeError: 'There was an error loading the videos. Please try again later.',
    beforeCall: 'Before you call us, maybe the information you need is already here',
    trainingHours: 'Training Hours',
    callUs: 'Call Us',
    whatsappUs: 'WhatsApp Us',
    enterEmail: 'Enter your email address',
    enterName: 'Enter your name',
    enterMobile: 'Enter your mobile number',
    invalidEmail: 'Invalid email address',
    wantUpdates: 'I want to receive updates and offers to my email',
    subscriptionSuccess: 'Subscription successful!',
    subscriptionError: 'Subscription failed. Please try again later.',
    subscribeButton: 'Subscribe',
    directions: 'Directions',
    visitYoutubeChannel: 'Want to see more videos by Shai Levi? - Visit the channel',
    subscribe: 'Subscribe to receive updates on new videos',
    accessibilityOptions: 'Accessibility Options',
    increaseFontSize: 'Increase Font Size',
    decreaseFontSize: 'Decrease Font Size',
    toggleDarkMode: 'Toggle Dark Mode',
    toggleBrightMode: 'Toggle Bright Mode',
    toggleHighContrast: 'Toggle High Contrast',
    toggleInvertedColors: 'Invert Colors',
    reset: 'Reset',
    closeMenu: 'Close Menu',
    newLicenseDescription: 'Guide to obtaining a new gun license',
    ourGunsDescription: 'Check out our wide range of firearms.',
    learnMore: 'Learn More',
    getStarted: 'Get Started',
    seeCollection: 'See Collection',
    unsubscribeTitle: "Unsubscribe from our Newsletter",
    unsubscribeButton: "Unsubscribe",
    unsubscribeSuccess: "Successfully unsubscribed!",
    unsubscribeNotFound: "Email not found.",
    unsubscribeFail: "Failed to unsubscribe.",
    helloSubscribers: 'Hello Gunners!',
    bestWishes: 'We would be happy to see you',
    signature: 'Shooting Range Shai Levi - Karnei Shomron',
    unsubscribeText: 'Want to unsubscribe from our website? Please enter ',
    unsubscribeLinkTitle: 'here',
    unsubscribeLink: 'Shailevi.com/unsubscribe',
    sundayToThursday: 'Sunday to Thursday',
    friday: 'Friday and holidays',
    home: 'Home',
    management: 'Management',
    logout: 'Logout',
    prev: 'Previous',
    next: 'Next',
    registerTitle: 'Training Registration',
    formLanguageNotice: 'Please fill out the form in Hebrew only. If you have any trouble, please refer to our staff for help.',
    registrationType: 'Training Type',
    fullName: 'Full Name',
    phoneNumber: 'Phone Number',
    teudatZehut: 'Teudat Zehut (ID)',
    gunLicenseId: 'Gun License ID',
    email: 'Email',
    registerForNewsletter: 'Register for newsletter',
    registerButton: 'Register',
    selectOption: 'Select an option',
    licenseRenewal: 'License Renewal',
    licenseRefresh: 'License Refresh',
    freeShooting: 'Free Shooting',
    allFieldsRequired: 'All fields are required.',
    registrationSuccess: 'Registration successful!',
    signatureRequest: 'Please sign here',
    intro: "Introduction",
    eligibility: "Required Documents",
    requiredDocs: "Application Submission",
    licenseIssuance: "License Issuance",
    trainingInfo: "Training Information",
    register: 'Register for Training',
    welcomeToRegistration: "Welcome to the Registration Process",
    loginOrGuest: "Please choose to login or continue as a guest:",
    login: "Login",
    fillAsGuest: "Fill as Guest",
    fasterRegistration: "Faster registration now and in future times.",
    
    yourNumberIs: "Your number is",
    registrationFailed: "Registration failed. Please try again.",
    imprintOnPistol: "Imprint number on Pistol",
    firstTraining: "First Training",
    corporateTraining45: "Corporate Training 4.5mm",
    corporateTraining9: "Corporate Training 9mm",
    inWallSafeCommitment: "In-Wall Safe Commitment",
    commitmentText: "I, <user name>, <teudat_zehut>, commit that: 1. I am aware that according to the guidelines of the Licensing and Supervision Division of Firearms at the Ministry of National Security, published on the ministry's website, I am required to store the firearm registered in the license I will receive in a safe installed in my home. 2. The safe will be installed in a hidden place under my control and responsibility. 3. I commit that the safe will be used only for storing a firearm in my possession, in a manner that will not allow another person, including other household members, access to the firearm. 4. I am aware that violating this commitment is equivalent to violating the license conditions for holding a firearm, with all that it entails.",
    street: "Street",
    homeNumber: "Home Number",
    city: "City",
    zip: "Zip Code",
    organizationNumber: "Organization Number",
    holsterWarning: " The next details are shown in the gun license and under no circumstances you are allowed to get the gun out of the holster.",
    confirmCommitment: "I confirm the commitment",
    healthDeclarationTitle: 'Health Declaration',
    healthDeclarationText: 'I, <user name>, <teudat_zehut>, declare that for the purpose of obtaining a gun license, according to the regulations of the Licensing and Supervision Division of Firearms (2018):',
    healthDeclarationConfirm: ' There is no change in my health condition since the last health declaration I submitted.',
    healthUpdateConfirm: ' There is a change in my health condition since the last health declaration I submitted, and I have attached a new health declaration.',
    signatureRequired: 'Signature is required',
    HealthDeclarationRequired: 'You need to confirm the health declaration',
    guestLoginPrompt : 'Would you like to login so we can save your details for future registrations? - It will make the process faster next time.',
    usedPistolsTitle: "Used Pistols",
    usedPistolsDisclaimer: "The list of used pistols may change from time to time. Subject to errors.",
    manufacturer: "Manufacturer",
    model: "Model",
    year: "Year",
    price: "Price",
    comments: "Comments",
    ourGunsErrors: "There may be a discrepancy between the image and the displayed model. Please verify details in-store. E&OE." ,
    close: "Close",
    newLicenseTitle2: 'What to do to request a gun license?',
    licenseExplanation: 'I decided to explain and guide you through the steps to make it easier for you. Links to the required forms are also attached. For any questions, feel free to call:',
    shootingRange: 'Shooting Range Shai Levi (Karnei Shomron)',
    contactDetails: 'Shai Levi: 0585914713',
    youtubeVideo: 'Here is a video of mine that explains how to choose a gun:',
    healthDeclarationInfo: 'Gun health declaration, to be signed by your family doctor. (Not from a first-degree relative, so if your spouse is a doctor, 👨‍⚕️👩‍⚕️ congratulations, save it for the aspirin. Their signature does not count.)',
    idWithAppendix: 'ID card including the appendix.',
    militaryApproval: 'Under 27 and after regular service. You need military or national service approval. No need for this approval if you are over 27.🧓👵',
    eligibilityCriteria: 'Approval for one of the criteria for applying for a gun license. Eligibility can be checked on the Ministry of Public Security’s website.',
    eligibilityExamples: 'For example:',
    eligibleSettlement: 'Resident of an eligible settlement (get it from your local authority’s taxation department).',
    combatCertificate: 'Combat soldier certificate, Rifleman 03.👨‍✈️👩‍✈️',
    officerCertificate: 'Officer or non-commissioned officer certificate.',
    idfApprovalLink: 'Here is the link to Form 830 on the IDF website, for the approval of Rifleman and/or rank:',
    otherCriteria: 'Approval for any other criterion.',
    eligibilityCalculator: 'If you are unsure about the criterion, here is a link to the Firearm Eligibility Calculator.',
    scanAndSubmit: 'Scan and submit everything on the Ministry of Public Security’s website. They will contact you and invite you for an interview. If you are invited for an interview, there is a high chance you will receive conditional approval👇',
    ministryLink: 'Application submission to the Ministry of Public Security',
    afterInterview: 'What to do after the interview at the Ministry of Public Security:',
    conditionalApprovalReceived: 'After the interview, you will receive conditional approval and a fee payment notice, which can be paid online.',
    visitShootingRange: 'Come to our shooting range in Karnei Shomron with the conditional approval and your ID/driving license/passport.',
    chooseGun: 'Choose a gun that suits you; of course, I will recommend and help you with the decision.',
    trainingSession: 'Complete a training session with the gun you chose.',
    documentsSubmission: 'At the end of the training session, you will receive several forms from me.',
    getTemporaryLicense: 'Within 48 hours (depending on the Ministry of Public Security’s workload), the Ministry will send you back the temporary license and gun sale approval by email (meaning the gun is registered under your name).',
    collectGun: 'Print the temporary license and the gun sale approval, and come to the Karnei Shomron shooting range to collect your gun.',


    
  },
  he: {
    welcome: "ברוכים הבאים",
    loading: "טוען...",
    gunRangeTitle: "מטווח שי לוי, קרני שומרון",
    renewalHoursTitle: "שעות אימון - רענון/חידוש רישיון",
    renewalHoursContentWeekdays: "ימי א' עד ה': 9:00, 14:00, 17:00 ",
    renewalHoursContentWeekends: " ימי שישי וערבי חג: 8:00, 12:00",
    firstTrainingHoursTitle: "שעות אימון הכשרה ראשונית",
    firstTrainingHoursWeekdays: "ימי א' עד ה': 9:00, 14:00",
    firstTrainingHoursWeekends: " ימי שישי וערבי חג: 8:00",
    officeHoursTitle: "שעות קבלת קהל",
    officeHoursContentWeekdays: "ימי א' עד ה': 9:00 עד 17:30",
    officeHoursContentWeekends: " ימי שישי וערבי חג: 8:00 עד 12:00",
    arrival: "אין צורך לקבוע תור לאימון - הגעה חופשית",
    welcomeMessage: 'ברוכים הבאים למטווח שלנו!',
    aboutUsTitle: 'אודות המטווח',
    aboutUsDescription: ' מטווח שי לוי, קרני שומרון',
    aboutUsContent: 
    'מטווח שי לוי בקרני שומרון הוקם בשנת 2017 על ידי שי לוי, מדריך נשק וקרב מגע בעל ניסיון רב בתחום. המטווח נבנה מתוך חזון להעניק הכשרה איכותית ובטוחה לכל חובבי הנשק ולכאלה המבקשים להעמיק את הידע והמיומנות שלהם בשימוש נכון ואחראי בכלי נשק. המקום מתמחה במגוון רחב של הדרכות, החל מהדרכות בסיסיות ועד לאימונים מתקדמים בשימוש בנשק וקרב מגע, תוך הקפדה על סטנדרטים גבוהים של מקצועיות ובטיחות. המטווח מציע סביבה מקצועית ונעימה לכל מי שמעוניין ללמוד ולשפר את כישוריו בתחומים אלו, תוך ליווי אישי והכוונה של צוות מיומן ומנוסה.',
    newLicenseTitle: 'רישיון חדש',
    newLicenseIntroduction: 'תנאי זכאות להוצאת רישיון:',
    newLicense1: 'לפני הכל, עלייך לוודא שאתה עומד באחד מהתנאים הבאים:',
    newLicense2: 'מגורים ביישוב זכאי',
    conditionalApproval: 'יש לי אישור מותנה',
    eligibleWithoutApproval: '*אין לי* את המסמכים הדרושים',
    requiredDocsIntro: '*יש לי* את המסמכים הדרושים',
    gotDocs: 'יש לי את המסמכים',
    backToStart: 'חזרה להתחלה',
    requiredDocsTitle: 'רשימת המסמכים שעליכם להמציא על מנת להוציא רישיון:',
    healthDeclaration: 'אישור הצהרת בריאות חתום מרופא המשפחה',
    downloadForm: 'להורדת טופס',
    applicationDocsSubtitle: 'עבור טופס בקשה להוצאת רישיון יש להמציא מסמכים עבור התבחין שבגינו אתם זכאים להוצאת רישיון לאקדח:',
    residencyCertificate: 'מסמך המאשר מגורים ביישוב זכאי',
    contactMunicipality: 'יש לפנות למועצה / עירייה מקומית לצורך קבלת מסמך זה',
    or: 'או',
    securityForcesService: 'מסמך המאשר שירות בכוחות הביטחון/צה"ל',
    idfWebsite: 'אתר האישורים של צה"ל',
    applicationOnlineIntro: 'יש למלא את הבקשה לאישור מותנה להוצאת רישיון לאקדח:',
    applicationForm: 'טופס בקשה להוצאת רישיון לנשיאת כלי יריה',
    fillOnline: 'מילוי הטופס אונליין',
    passportPhoto: 'צילום תמונת פספורט',
    idAndAppendix: 'צילום תעודת זהות וספח',
    conditionalApprovalSent: 'לאחר אישור הבקשה ישלח טופס “אישור מותנה” למייל שאיתו נרשמים לאימון ראשוני',
    licenseIssuanceStepsTitle: 'כעת ממתינים לאישור המותנה, יכול לקחת כמה ימים ואפילו כמה שבועות, התאזרו בסבלנות - ומה אחרי?',
    step1: 'קבלת טופס אישור מותנה מהמשרד לביטחון לאומי',
    step2: 'הגעה לאימון הכשרה - אין צורך בתור',
    step3: 'רכישת אקדח - נציג לכם את האקדחים שלנו ונענה על כל שאלותיכם',
    step4: 'קבלת רישיון זמני (לפי זמני העבודה במשרד לביטחון לאומי)',
    step5: 'איסוף האקדח',
    step6: 'קבלת רישיון קבוע',
    whenToCome: 'מתי להגיע?',
    conditionalApprovalAcquired: ' ברשותכם אישור מותנה להוצאת רישיון אקדח',
    trainingHoursTitle: 'שעות אימון:',
    trainingWeekdays: 'ראשון - חמישי: אימון בוקר בשעה 09:00, אימון צהריים בשעה 14:00',
    trainingWeekends: 'שישי: אימון בוקר בשעה 08:00 - בדרך כלל עמוס מאוד',
    noReservationRequired: 'ניתן להגיע ללא הרשמה',
    trainingCost: 'עלות הכשרה 500 ש"ח - מותנה ברכישת אקדח, ללא רכישת אקדח - עלות ההכשרה הינה 650 ש"ח',
    gunCost: 'עלות אקדח החל מ 3500 ש"ח',
    initialTrainingTitle: 'אימון הכשרה ראשוני:',
    initialTrainingDetails: 'האימון אורך כ- 5 שעות וכולל שיעור ומבחן עיוני, למידה על סוגי הכלים השונים, ייעוץ והכוונה בבחירת אקדח, אימון יבש ואימון מעשי בירי חם.',
    trainingRequirements: 'יש להגיע עם מכנסיים ארוכות ונעליים סגורות',
    contactTitle: 'צור קשר',
    footerAddress: 'מטווח שי לוי - קרני שומרון',
    address: 'קרני שומרון, רחבעם זאבי 1',
    footerPhone: '058-591-4713',
    joinCommunity: 'הצטרפו לקהילה שלנו',
    wantUpdates: 'אני מעוניין לקבל עדכונים והצעות למייל',
    loginButton: 'התחברות',
    loginPromotion: 'התחברו ותחסכו זמן בהרשמות עתידיות',
    ourGunsTitle: 'המבחר שלנו',
    weaponReviewsTitle: "סרטוני אקדחים",
    weaponReviewsDescription: "סרטוני הדרכה וביקורות אקדחים",
    glock: 'גלוק',
    sigSauer: 'זיג זאוור',
    springfield: 'ספרינגפילד',
    mossberg: 'מוסברג',
    iwi: 'IWI',
    shadowSystems: 'שאדו סיסטמס',
    cz: 'CZ',
    sw: 'סמית אנד ווסון',
    newestVideos: 'סרטונים חדשים',
    topViewedVideos: 'הסרטונים הנצפים ביותר',
    youtubeError: 'אירעה שגיאה בטעינת הסרטונים. נסה שוב מאוחר יותר.',
    beforeCall: 'רגע לפני שאתם מתקשרים, אולי המידע שאתם מחפשים כבר כאן',
    trainingHours: 'שעות המטווח',
    callUs: 'שיחת טלפון',
    whatsappUs: 'צור קשר בוואטסאפ',
    enterEmail: 'הזן את כתובת האימייל שלך',
    enterName: 'הזן את שמך',
    enterMobile: 'הזן את מספר הטלפון שלך',
    invalidEmail: 'כתובת אימייל לא חוקית',
    subscriptionSuccess: 'ההרשמה הצליחה!',
    subscriptionError: 'ההרשמה נכשלה. אנא נסו שוב מאוחר יותר.',
    subscribeButton: 'הירשם',
    directions: 'הוראות הגעה',
    visitYoutubeChannel: 'רוצים לראות עוד סרטונים של שי לוי? - היכנסו לערוץ',
    subscribe: ' הירשמו לקבלת עדכונים על סרטונים חדשים',
    accessibilityOptions: 'אפשרויות נגישות',
    increaseFontSize: 'הגדל גופן',
    decreaseFontSize: 'הקטן גופן',
    toggleDarkMode: 'החלף למצב כהה',
    toggleBrightMode: 'החלף למצב בהיר',
    toggleHighContrast: 'החלף לניגודיות גבוהה',
    toggleInvertedColors: 'הפוך צבעים',
    reset: 'איפוס',
    closeMenu: 'סגור תפריט',
    newLicenseDescription: 'מדריך להוצאת רישיון חדש לנשק',
    ourGunsDescription:'היכנסו וראו את מבחר האקדחים שלנו.',
    learnMore: 'למד עוד',
    getStarted: 'התחל',
    seeCollection: 'ראה את האוסף',
    unsubscribeTitle: "ביטול רישום לניוזלטר שלנו",
    unsubscribeButton: "בטל רישום",
    unsubscribeSuccess: "ביטול רישום בהצלחה!",
    unsubscribeNotFound: "האימייל לא נמצא.",
    unsubscribeFail: "נכשל בביטול הרישום.",
    helloSubscribers: 'שלום אקדחנים!',
    bestWishes: 'נשמח לראותכם',
    signature: 'מטווח שי לוי - קרני שומרון',
    unsubscribeText: ' מעוניין לבטל את הרישום לאתר שלנו? לחץ .',
    unsubscribeLinkTitle: 'להסרה כאן',
    unsubscribeLink: 'Shailevi.com/unsubscribe',
    sundayToThursday: 'ימי ראשון - חמישי',
    friday: 'יום שישי וערבי חג',
    home: 'בית',
    management: 'ניהול',
    logout: 'התנתק',
    prev: 'חזור',
    next: 'הבא',
    registerTitle: 'הרשמה לאימון',
    formLanguageNotice: 'אנא מלא את הטופס בעברית בלבד. אם אתה זקוק לעזרה, אנא פנה לצוות שלנו ונשמח לעמוד לרשותך',
    registrationType: 'סוג אימון',
    fullName: 'שם מלא',
    phoneNumber: 'מספר טלפון',
    teudatZehut: 'תעודת זהות',
    gunLicenseId: 'מספר רישיון נשק',
    email: 'דוא"ל',
    registerForNewsletter: 'הירשם לניוזלטר',
    registerButton: 'הירשם',
    selectOption: 'בחר אפשרות',
    licenseRenewal: 'חידוש רישיון',
    licenseRefresh: 'רענון רישיון',
    freeShooting: 'ירי חופשי',
    allFieldsRequired: 'כל השדות חובה.',
    registrationSuccess: 'הרשמה הצליחה!',
    signatureRequest: 'אנא חתום כאן',
    intro: "תנאי זכאות",
    eligibility: "שלבים להוצאת רישיון",
    requiredDocs: "הגשת בקשה",
    licenseIssuance: "הנפקת רישיון",
    trainingInfo: "מידע על אימונים",
    register: 'הרשמה לאימון',
    welcomeToRegistration: "ברוכים הבאים לתהליך ההרשמה",
    loginOrGuest: "אנא בחרו להיכנס או להמשיך כאורחים:",
    login: "כניסה",
    fillAsGuest: "הרשמה כאורח",
    fasterRegistration: "הרשמה מהירה כעת ובפעמים הבאות.",
    imprintOnPistol : "מספר כלי על האקדח",
    yourNumberIs: "המספר שלך הוא",
    registrationFailed: "ההרשמה נכשלה. אנא נסו שוב.",
    firstTraining: "הכשרה ראשונית",
    corporateTraining45: "הכשרה תאגידית 4.5",
    corporateTraining9: "הכשרה תאגידית 9",
    inWallSafeCommitment: "התחייבות מקבל/ת רישיון להתקנת כספת",
    commitmentText: "אני, <user name>, <teudat_zehut>, מתחייב כי: 1. ידוע לי כי בהתאם להנחיות אגף לרישוי ופיקוח כלי ירייה במשרד לביטחון לאומי, המפורסמות באתר המשרד, עליי לאחסן את כלי הירייה הרשום ברישיון שאקבל בכספת שתותקן בביתי. 2. הכספת תותקן במקום מוסתר המצוי בשליטתי ואחריותי. 3. אני מתחייב/ת כי הכספת תשמש לאחסון כלי ירייה הנמצא בחזקתי בלבד, באופן שלא יתאפשר לאדם אחר, לרבות ליתר דיירי הבית, גישה אל כלי הירייה. 4. ידוע לי כי הפרת התחייבות זו כמוה כהפרת תנאי רישיון להחזקת כלי ירייה, על כל המשתמע.",
    street: "רחוב",
    homeNumber: "מספר בית",
    city: "עיר",
    zip: "מיקוד",
    organizationNumber: "מספר ארגון",
    holsterWarning:"בשום פנים ואופן אין להוציא את האקדח מהנרתיק - את הנתונים הבאים ניתן להשיג מהרישיון/אישור מותנה.",
    confirmCommitment : "אני מאשר את ההתחייבות",
    healthDeclarationTitle: 'הצהרת בריאות',
    healthDeclarationText: 'אני, <user name>, ת.ז <teudat_zehut>, מצהיר/ה בזה בכתב כי לצורך ביצוע הכשרת רענון בהתאם לתקנות כלי הירייה התשע"ח-2018:',
    healthDeclarationConfirm: 'לא חל כל שינוי בפרטי הצהרת בריאותי הקודמת שמסרתי לאגף הרישוי כלי ירייה במשרד לביטחון הפנים (להלן: "הרשות המוסמכת"), בתמיכה לבקשתי לקבלת/חידוש רישיון לנשיאת כלי ירייה',
    healthUpdateConfirm: 'חל שינוי בפרטים שמסרתי לרשות המוסמכת, מצ"ב הצהרת בריאות עדכנית.',
    signatureRequired: 'חובה לחתום במקום המיועד',
    HealthDeclarationRequired: 'יש לאשר את הסעיף הנכון בהצהרת הבריאות',
    guestLoginPrompt: 'היית מעוניין להתחבר על מנת שנשמור את הפרטים שלך לפעם הבאה? - זה מאוד יקל עליך בהרשמות הבאות',
    usedPistolsTitle: "אקדחים משומשים",
    usedPistolsDisclaimer: "רשימת האקדחים המשומשים משתנה מעת לעת - ט.ל.ח",
    manufacturer: "יצרן",
    model: "דגם",
    year: "שנה",
    price: "מחיר",
    comments: "הערות",
    ourGunsErrors: 'תיתכן טעות בין התמונה לדגם המוצג. ניתן לבדוק במקום - ט.ל.ח.',
    close: 'סגור',
    newLicenseTitle2:'מה צריך לעשות לבקשת רישיון אקדח?',
    licenseExplanation: 'החלטתי להסביר ולהנחות את השלבים, רק להקל עליכם. מצורפים גם לינקים לטפסים הדרושים. לכל שאלה מוזמנים להתקשר:',
    shootingRange: 'מטווח שי לוי (קרני שומרון)',
    contactDetails: 'שי לוי: 0585914713',
    youtubeVideo: 'מצורף גם סירטון שלי, שמסביר איך לבחור אקדח',
    healthDeclarationInfo: 'הצהרת בריאות כלי ירייה, להחתים את רופא/ת המשפחה. ( לא מדרגה ראשונה, אז אם בן/בת הזוג רופאים. 👨‍⚕️👩‍⚕️מזל טוב, תשמרו את זה לאקמול. לא תופס החתימה שלהם. )',
    idWithAppendix: 'תעודת זהות כולל הספח.',
    militaryApproval: 'מתחת לגיל 27, ואחרי שירות סדיר. צריך אישור צבאי או שירות לאומי. אין צורך באישור כזה אם אתם מעל גיל 27.🧓👵',
    eligibilityCriteria: 'אישור עבור אחד התבחינים לבקשת רישיון כלי יריה. ניתן לברר זכאות באתר לביטחון פנים.',
    eligibilityExamples: 'למשל:',
    eligibleSettlement: 'תושב ישוב זכאי (לוקחים מהגביה אצלכם בישוב).',
    combatCertificate: 'תעודת לוחם רובאי 03.👨‍✈️👩‍✈️',
    officerCertificate: 'תעודת קצין או רס"ר.',
    idfApprovalLink: 'מצורף לינק לטופס 830 מאתר צה"ל, לאישור רובאי ו/או דרגה:',
    otherCriteria: 'אישור עבור כל תבחין אחר.',
    eligibilityCalculator: 'אם אתם לא בטוחים בתבחין, מצורף לינק למחשבון זכאות כלי ירייה.',
    scanAndSubmit: 'לשלוח הכל סרוק באתר לביטחון פנים, בקשה לכלי ירייה. הם יצרו איתכם קשר ויזמינו אותכם לראיון, סביר להניח שאם הוזמנתם לראיון אז סיכוי גבוה שתקבלו אישור מותנה👇',
    ministryLink: 'הגשת בקשה למשרד לביטחון פנים',
    afterInterview: 'מה עושים לאחר הראיון:',
    conditionalApprovalReceived: 'לאחר הראיון, תקבלו אישור מותנה ותשלום אגרה, ניתן לשלם באינטרנט.',
    visitShootingRange: 'מגיעים אלינו למטווח קרני שומרון עם האישור המותנה ות.ז/רישיון נהיגה/דרכון.',
    chooseGun: 'בוחרים אקדח שיתאים לך, כמובן שאמליץ ואעזור לך בהחלטה.',
    trainingSession: 'עושה אימון הכשרה על האקדח שבחרת.',
    documentsSubmission: 'בסיום האימון הכשרה, תקבלו ממני מספר טפסים.',
    getTemporaryLicense: 'תוך 48 שעות, (תלוי בלחץ של הבטפ) .הבטפ ישלח לך חזרה במייל את הרישיון הזמני לאישור מכירת אקדח (כלומר שהאקדח עבר על שמכם)',
    collectGun: 'תדפיסו את הרישיון הזמני והאישור מכירת האקדח, ותגיעו למטווח קרני שומרון לקחת את האקדח.',


  },
  ru: {
    welcome: 'Добро пожаловать',
    loading: 'Загрузка...',
    gunRangeTitle: 'Стрельбище Шай Леви, Карней Шомрон',
    renewalHoursTitle: 'Часы обучения - обновление/новая лицензия',
    renewalHoursContentWeekdays: 'Воскресенье-Четверг: 9:00, 14:00, 17:00',
    renewalHoursContentWeekends: 'Пятница и праздники: 8:00, 12:00',
    firstTrainingHoursTitle: 'Часы начального обучения',
    firstTrainingHoursWeekdays: 'Воскресенье-Четверг: 9:00, 14:00',
    firstTrainingHoursWeekends: 'Пятница и праздники: 8:00',
    officeHoursTitle: 'Часы работы офиса',
    officeHoursContentWeekdays: 'Воскресенье-Четверг: 9:00 до 17:30',
    officeHoursContentWeekends: 'Пятница и праздники: 8:00 до 12:00',
    arrival: 'Не нужно записываться на обучение - свободный вход',
    welcomeMessage: 'Добро пожаловать на наше стрельбище!',
    aboutUsTitle: 'О нас',
    aboutUsDescription: 'Стрельбище Шай Леви, Карней Шомрон',
    aboutUsContent: 'Стрелковый тир Шая Леви в Карней Шомрон был основан в 2017 году Шаем Леви, инструктором по огнестрельному оружию и Крав Мага с обширным опытом в этой области. Тир был создан с видением предоставления качественной и безопасной подготовки для любителей огнестрельного оружия и тех, кто хочет углубить свои знания и навыки в ответственном и правильном использовании оружия. Учреждение специализируется на широком спектре тренировок, от базовых курсов до продвинутых тренировок по стрельбе и Крав Мага, с соблюдением высоких стандартов профессионализма и безопасности. Тир предлагает профессиональную и дружелюбную атмосферу для всех, кто интересуется обучением и улучшением своих навыков в этих областях, с персональным сопровождением и наставничеством от опытной и квалифицированной команды.',
    newLicenseTitle: 'Новая лицензия',
    newLicenseIntroduction: 'Итак, вы хотите получить лицензию на оружие - вы пришли по адресу, но сначала:',
    conditionalApproval: 'У меня есть условное одобрение',
    eligibleWithoutApproval: 'У меня нет условного одобрения, но я имею право:',
    requiredDocsIntro: 'У меня есть условное одобрение и все необходимые документы',
    gotDocs: 'У меня есть документы',
    backToStart: 'Вернуться к началу',
    requiredDocsTitle: 'Список документов, которые вам нужно получить для получения лицензии:',
    healthDeclaration: 'Подписанная декларация о состоянии здоровья от вашего семейного врача',
    downloadForm: 'Скачать форму',
    applicationDocsSubtitle: 'Для формы заявки вам необходимо получить документы по критериям, на основании которых вы имеете право на получение лицензии на оружие:',
    residencyCertificate: 'Свидетельство о проживании в подходящем поселении',
    contactMunicipality: 'Обратитесь в местный совет/муниципалитет для получения этого документа',
    or: 'или',
    securityForcesService: 'Документ, подтверждающий службу в силах безопасности/Армии обороны Израиля',
    idfWebsite: 'Сайт подтверждений ЦАХАЛа',
    applicationOnlineIntro: 'Получили документы? Теперь вы можете заполнить заявку онлайн:',
    applicationForm: 'Форма заявки на ношение огнестрельного оружия',
    fillOnline: 'Заполнить форму онлайн',
    passportPhoto: 'Фотография паспорта',
    idAndAppendix: 'Паспорт и приложение',
    conditionalApprovalSent: 'После одобрения заявки на вашу электронную почту будет отправлена форма "условного одобрения", которую вы будете использовать для регистрации на начальное обучение.',
    licenseIssuanceStepsTitle: 'Этапы выдачи лицензии:',
    step1: 'Получение формы условного одобрения от Министерства национальной безопасности',
    step2: 'Прибытие на начальное обучение - запись не требуется',
    step3: 'Покупка оружия - мы покажем вам наши пистолеты и ответим на все ваши вопросы',
    step4: 'Получение временной лицензии (в соответствии с рабочими часами Министерства национальной безопасности)',
    step5: 'Получение оружия',
    step6: 'Получение постоянной лицензии',
    whenToCome: 'Когда приходить?',
    conditionalApprovalAcquired: 'У вас есть условное одобрение на получение лицензии на оружие',
    trainingHoursTitle: 'Часы обучения:',
    trainingWeekdays: 'Воскресенье-Четверг: Утреннее обучение в 09:00, Дневное обучение в 14:00',
    trainingWeekends: 'Пятница: Утреннее обучение в 08:00 - обычно очень загружено',
    noReservationRequired: 'Запись не требуется',
    trainingCost: 'Стоимость обучения 500 шекелей - при условии покупки оружия, без покупки оружия - стоимость обучения 650 шекелей',
    gunCost: 'Стоимость оружия начинается от 3500 шекелей',
    initialTrainingTitle: 'Начальное обучение:',
    initialTrainingDetails: 'Обучение длится около 5 часов и включает в себя теоретический урок и экзамен, изучение различных видов огнестрельного оружия, консультации и руководство по выбору оружия, сухие тренировки и обучение с огнем.',
    trainingRequirements: 'Необходимо носить длинные брюки и закрытую обувь',
    contactTitle: 'Связаться с нами',
    footerAddress: 'Стрельбище Шай Леви, Кирьят Шомрон',
    address: 'Кирьят Шомрон, Рахавам Зеави 1',
    footerPhone: '058-591-4713',
    joinCommunity: 'Присоединяйтесь к нашему сообществу',
    loginButton: 'Войти',
    loginPromotion: 'Войдите и сэкономьте время на будущие регистрации',
    ourGunsTitle: 'Наши пистолеты',
    glock: 'Глок',
    sigSauer: 'Зиг Зауэр',
    springfield: 'Спрингфилд',
    mossberg: 'Моссберг',
    iwi: 'IWI',
    shadowSystems: 'Шэдоу Системс',
    cz: 'CZ',
    sw: 'Смит энд Вессон',
    newestVideos: 'Новейшие видео',
    topViewedVideos: 'Самые просматриваемые видео',
    youtubeError: 'Произошла ошибка при загрузке видео. Пожалуйста, попробуйте позже.',
    beforeCall: 'Перед тем как позвонить нам, возможно, нужная вам информация уже здесь',
    trainingHours: 'Часы тренировок',
    callUs: 'Позвоните нам',
    whatsappUs: 'Связаться с нами по WhatsApp',
    enterEmail: 'Введите ваш адрес электронной почты',
    enterName: 'Введите ваше имя',
    enterMobile: 'Введите ваш номер телефона',
    invalidEmail: 'Неверный адрес электронной почты',
    wantUpdates: 'Я хочу получать обновления и предложения на свою почту',
    subscriptionSuccess: 'Подписка успешна!',
    subscriptionError: 'Подписка не удалась. Пожалуйста, попробуйте позже.',
    subscribeButton: 'Подписаться',
    directions: 'Маршруты',
    visitYoutubeChannel: 'Хотите увидеть больше видео от Шай Леви? - Посетите канал',
    subscribe: 'Подпишитесь, чтобы получать обновления о новых видео',
    accessibilityOptions: 'Опции доступности',
    increaseFontSize: 'Увеличить размер шрифта',
    decreaseFontSize: 'Уменьшить размер шрифта',
    toggleDarkMode: 'Переключить темный режим',
    toggleBrightMode: 'Переключить светлый режим',
    toggleHighContrast: 'Переключить высокий контраст',
    toggleInvertedColors: 'Инвертировать цвета',
    reset: 'Сбросить',
    closeMenu: 'Закрыть меню',
    newLicenseDescription: 'Руководство по получению новой лицензии на оружие',
    ourGunsDescription: 'Посмотрите наш широкий ассортимент оружия.',
    weaponReviewsTitle: 'Обзоры оружия',
    weaponReviewsDescription: 'Обучающие видео и обзоры оружия',
    learnMore: 'Узнать больше',
    getStarted: 'Начать',
    seeCollection: 'Посмотреть коллекцию',
    unsubscribeTitle: "Отписаться от нашей рассылки",
    unsubscribeButton: "Отписаться",
    unsubscribeSuccess: "Успешно отписаны!",
    unsubscribeNotFound: "Электронная почта не найдена.",
    unsubscribeFail: "Не удалось отписаться.",
    helloSubscribers: 'Привет стрелкам!',
    bestWishes: 'Будем рады видеть вас',
    signature: 'Стрельбище Шай Леви - Карней Шомрон',
    unsubscribeText: 'Хотите отписаться от нашего сайта? Пожалуйста, введите ',
    unsubscribeLinkTitle: 'здесь',
    unsubscribeLink: 'Shailevi.com/unsubscribe',
    sundayToThursday: 'Воскресенье-Четверг',
    friday: 'Пятница и праздники',
    home: 'Главная',
    management: 'Управление',
    logout: 'Выйти',
    prev: 'Назад',
    next: 'Далее',
    registerTitle: 'Регистрация на обучение',
    formLanguageNotice: 'Пожалуйста, заполните форму только на иврите. Если у вас возникнут проблемы, обратитесь к нашему персоналу за помощью.',
    registrationType: 'Тип обучения',
    fullName: 'Полное имя',
    phoneNumber: 'Номер телефона',
    teudatZehut: 'Идентификационный номер (Теудат Зеут)',
    gunLicenseId: 'Номер лицензии на оружие',
    email: 'Электронная почта',
    registerForNewsletter: 'Подписаться на рассылку',
    registerButton: 'Зарегистрироваться',
    selectOption: 'Выберите опцию',
    licenseRenewal: 'Продление лицензии',
    licenseRefresh: 'Обновление лицензии',
    freeShooting: 'Свободная стрельба',
    allFieldsRequired: 'Все поля обязательны для заполнения.',
    registrationSuccess: 'Регистрация прошла успешно!',
    signatureRequest: 'Пожалуйста, подпишите здесь',
    intro: "Введение",
    eligibility: "Необходимые документы",
    requiredDocs: "Подача заявки",
    licenseIssuance: "Выдача лицензии",
    trainingInfo: "Информация об обучении",
    register: 'Зарегистрироваться на обучение',
    yourNumberIs: "Ваш номер",
    registrationFailed: "Регистрация не удалась. Попробуйте еще раз.",
    firstTraining: "Первичное обучение",
    corporateTraining45: "Корпоративное обучение 4.5",
    corporateTraining9: "Корпоративное обучение 9",
    inWallSafeCommitment: "Обязательство хранения в стенном сейфе",
    commitmentText: "Я, <user name>, <teudat_zehut>, обязуюсь: 1. Мне известно, что в соответствии с рекомендациями отдела по лицензированию и контролю оружия в министерстве национальной безопасности, опубликованными на сайте министерства, мне нужно хранить зарегистрированное оружие в лицензии в сейфе, который будет установлен в моем доме. 2. Сейф будет установлен в скрытом месте, находящемся под моим контролем и ответственностью. 3. Я обязуюсь, что сейф будет использоваться только для хранения оружия, находящегося в моем владении, таким образом, чтобы никто, включая других жильцов дома, не имел доступа к оружию. 4. Мне известно, что нарушение этого обязательства равносильно нарушению условий лицензии на хранение оружия, с соответствующими последствиями.",
    street: "Улица",
    homeNumber: "Дом",
    city: "Город",
    zip: "Индекс",
    organizationNumber: "Номер организации",
    imprintOnPistol: "Отпечаток на пистолете",
    holsterWarning: "Следующие данные можно получить из лицензии/условного одобрения - ни в коем случае не извлекайте оружие из кобуры.",
    confirmCommitment: "Я подтверждаю обязательство",
    healthDeclarationTitle: 'Заявление о состоянии здоровья',
    healthDeclarationText: 'Я, <user name>, <teudat_zehut>, заявляю письменно, что для получения обновленного обучения в соответствии с правилами оружия 2018 года:',
    healthDeclarationConfirm: 'Мои предыдущие заявления о состоянии здоровья не изменились',
    healthUpdateConfirm: 'Мои данные изменились, вот обновленное заявление о состоянии здоровья.',
    signatureRequired: 'Требуется подпись',
    HealthDeclarationRequired: 'Подтвердите правильность заявления о состоянии здоровья',
    guestLoginPrompt: 'Хотите войти, чтобы мы могли сохранить ваши данные для следующего раза? - Это значительно упростит ваши последующие регистрации.',
    usedPistolsTitle: "Б/у пистолеты",
    usedPistolsDisclaimer: "Список б/у пистолетов может изменяться время от времени. Ошибки возможны.",
    manufacturer: "Производитель",
    model: "Модель",
    year: "Год",
    price: "Цена",
    comments: "Комментарии",
    ourGunsErrors: "Возможно расхождение между изображением и представленным моделем. Пожалуйста, уточните детали в магазине. Ошибки и опечатки возможны.",
    close: 'Закрыть',
    newLicenseTitle2: 'Что нужно сделать для получения лицензии на оружие?',

    licenseExplanation: 'Я решил объяснить и провести вас через шаги, чтобы облегчить процесс. Прикреплены ссылки на необходимые формы. Если у вас есть вопросы, не стесняйтесь звонить:',
    shootingRange: 'Стрельбище Шай Леви (Карней Шомрон)',
    contactDetails: 'Шай Леви: 0585914713',
    youtubeVideo: 'Вот мое видео, в котором объясняется, как выбрать пистолет:',
    healthDeclarationInfo: 'Декларация о здоровье для оружия, подписанная вашим семейным врачом. (Не от родственника первой степени, так что если ваш супруг(а) врач, 👨‍⚕️👩‍⚕️ поздравляю, сохраните это для аспирина. Их подпись не считается.)',
    idWithAppendix: 'Удостоверение личности с приложением.',
    militaryApproval: 'Младше 27 лет и после регулярной службы. Вам нужно подтверждение о военной или национальной службе. Нет необходимости в этом подтверждении, если вам больше 27 лет.🧓👵',
    eligibilityCriteria: 'Подтверждение по одному из критериев для подачи заявки на лицензию на оружие. Проверить право можно на сайте Министерства общественной безопасности.',
    eligibilityExamples: 'Например:',
    eligibleSettlement: 'Житель подходящего поселения (получите его в налоговом отделе вашей местной власти).',
    combatCertificate: 'Свидетельство боевого солдата, Стрелок 03.👨‍✈️👩‍✈️',
    officerCertificate: 'Свидетельство офицера или унтер-офицера.',
    idfApprovalLink: 'Вот ссылка на форму 830 на сайте ЦАХАЛа, для подтверждения стрелка и/или звания:',
    otherCriteria: 'Подтверждение по любому другому критерию.',
    eligibilityCalculator: 'Если вы не уверены в критерии, вот ссылка на Калькулятор прав на оружие.',
    scanAndSubmit: 'Сканируйте и отправляйте все на сайте Министерства общественной безопасности. Они свяжутся с вами и пригласят на собеседование. Если вас пригласили на собеседование, то есть высокая вероятность, что вы получите условное одобрение👇',
    ministryLink: 'Подача заявки в Министерство общественной безопасности',
    afterInterview: 'Что делать после собеседования в Министерстве общественной безопасности:',
    conditionalApprovalReceived: 'После собеседования вы получите условное одобрение и уведомление об оплате сбора, которое можно оплатить онлайн.',
    visitShootingRange: 'Приходите на наше стрельбище в Карней Шомрон с условным одобрением и вашим удостоверением личности/водительскими правами/паспортом.',
    chooseGun: 'Выберите пистолет, который вам подходит; конечно, я порекомендую и помогу вам с решением.',
    trainingSession: 'Пройдите тренировочную сессию с выбранным вами пистолетом.',
    documentsSubmission: 'В конце тренировочной сессии вы получите от меня несколько форм.',
    getTemporaryLicense: 'В течение 48 часов (в зависимости от загруженности Министерства общественной безопасности), Министерство отправит вам временную лицензию и одобрение на продажу пистолета по электронной почте (это означает, что пистолет зарегистрирован на ваше имя).',
    collectGun: 'Распечатайте временную лицензию и одобрение на продажу пистолета и приходите на стрельбище Карней Шомрон, чтобы забрать свой пистолет.',
    

    
  },
};

export default text;

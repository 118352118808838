// src/components/OpeningHoursUpdater.js

import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/OpeningHoursUpdater.css';

const activities = [
  { key: 'shootingRange', label: 'שעות המטווח - שעות קבלת קהל' },
  { key: 'newLicense', label: 'שעות אימון - הכשרה לאקדח' },
  { key: 'freeShooting', label: 'ירי חופשי' },
  { key: 'licenseRenewal', label: 'חידוש או רענון' },
];

const defaultTimes = {
  sundayToThursday: '',
  fridays: '',
};

const OpeningHoursUpdater = () => {
  const [selectedActivity, setSelectedActivity] = useState('');
  const [times, setTimes] = useState({ ...defaultTimes });
  const [eventTimes, setEventTimes] = useState({ sundayToThursday: [], fridays: [] });

  useEffect(() => {
    if (selectedActivity) {
      // Fetch the existing times from the database
      const fetchTimes = async () => {
        const docRef = doc(db, 'openingHours', selectedActivity);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setTimes({ ...times, ...data });
          if (data.sundayToThursday instanceof Array) {
            setEventTimes({ ...eventTimes, ...data });
          }
        }
      };
      fetchTimes();
    }
  }, [selectedActivity]);

  const handleSave = async () => {
    try {
      const docRef = doc(db, 'openingHours', selectedActivity);
      if (selectedActivity === 'shootingRange') {
        await setDoc(docRef, { sundayToThursday: times.sundayToThursday, fridays: times.fridays }, { merge: true });
      } else {
        await setDoc(docRef, { sundayToThursday: eventTimes.sundayToThursday, fridays: eventTimes.fridays }, { merge: true });
      }
      alert('Opening hours updated successfully!');
    } catch (error) {
      console.error('Error updating opening hours:', error);
    }
  };

  return (
    <div className="opening-hours-updater">
      <h2>עדכון ושינוי שעות המטווח</h2>

      <div className="form-group">
        <select
          value={selectedActivity}
          onChange={(e) => {
            setSelectedActivity(e.target.value);
            setTimes({ ...defaultTimes });
            setEventTimes({ sundayToThursday: [], fridays: [] });
          }}
        >
          <option value="">בחר הגדרה</option>
          {activities.map((activity) => (
            <option key={activity.key} value={activity.key}>
              {activity.label}
            </option>
          ))}
        </select>
      </div>

      {selectedActivity && (
        <>
          <div className="form-group">
            <label>ימי ראשון - חמישי</label>
            {selectedActivity === 'shootingRange' ? (
              <input
                type="text"
                value={times.sundayToThursday}
                onChange={(e) => setTimes({ ...times, sundayToThursday: e.target.value })}
                placeholder="לדוגמא 09:00 - 12:00, 13:00 - 17:00"
              />
            ) : (
              <input
                type="text"
                value={eventTimes.sundayToThursday.join(', ')}
                onChange={(e) => setEventTimes({ ...eventTimes, sundayToThursday: e.target.value.split(',').map(time => time.trim()) })}
                placeholder="e.g., 09:00, 13:00"
              />
            )}
          </div>

          <div className="form-group">
            <label>ימי שישי וערבי חג</label>
            {selectedActivity === 'shootingRange' ? (
              <input
                type="text"
                value={times.fridays}
                onChange={(e) => setTimes({ ...times, fridays: e.target.value })}
                placeholder="לדוגמא 09:00 - 12:00"
              />
            ) : (
              <input
                type="text"
                value={eventTimes.fridays.join(', ')}
                onChange={(e) => setEventTimes({ ...eventTimes, fridays: e.target.value.split(',').map(time => time.trim()) })}
                placeholder="לדוגמא 09:00"
              />
            )}
            <p>יש להפריד בין אימון לאימון באמצעות פסיק - 09:00,11:00</p>
          </div>

          <button className="ohu-button" onClick={handleSave}>שמור שינויים</button>
        </>
      )}
    </div>
  );
};

export default OpeningHoursUpdater;

import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/TransactionList.css';

const TransactionList = ({ teudatZehut, onClose }) => {
  const [transactions, setTransactions] = useState([]);
  const [expandedTransaction, setExpandedTransaction] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const transactionsRef = collection(db, 'transactions');
        const q = query(transactionsRef, where('teudatZehut', '==', teudatZehut));
        const querySnapshot = await getDocs(q);
        
        const transactionList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setTransactions(transactionList);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [teudatZehut]);

  const handleExpand = (transactionId) => {
    setExpandedTransaction(expandedTransaction === transactionId ? null : transactionId);
  };

  const handleMarkAsPaid = async (transactionId) => {
    try {
      const transactionRef = doc(db, 'transactions', transactionId);
      await updateDoc(transactionRef, { status: 'Paid' });
      setTransactions(transactions.map(trans => trans.id === transactionId ? { ...trans, status: 'Paid' } : trans));
    } catch (error) {
      console.error('Error marking transaction as paid:', error);
    }
  };

  return (
    <div className="transaction-popup">
      <div className="transaction-popup-header">
        <h3>רשימת עסקאות - {teudatZehut}</h3>
        <button onClick={onClose}>סגור</button>
      </div>
      <div className="transaction-list">
        {transactions.length > 0 ? (
          transactions.map((transaction) => (
            <div key={transaction.id} className="transaction-item">
              <div className="transaction-summary" onClick={() => handleExpand(transaction.id)}>
                <p>תאריך: {new Date(transaction.createdAt).toLocaleDateString('he-IL')}</p>
                <p>יתרה: ₪{transaction.remainingAmount}</p>
                <p>סטטוס: {transaction.status || 'לא שולם'}</p>
              </div>
              {expandedTransaction === transaction.id && (
                <div className="transaction-details">
                  {transaction.transactionData.map((item, index) => (
                    <div key={index} className="transaction-detail-item">
                      <p>פריט: {item.itemName}</p>
                      <p>תיאור/כמות: {item.description}</p>
                      <p>מחיר: ₪{item.price}</p>
                      <p>סופק: {item.supplied ? '✔' : ''}</p>
                    </div>
                  ))}
                  <div className="transaction-actions">
                    <button onClick={() => handleMarkAsPaid(transaction.id)}>סמן כשולם</button>
                    <button onClick={() => window.open(transaction.transactionPdfUrl)}>פתח קובץ</button>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>לא נמצאו עסקאות</p>
        )}
      </div>
    </div>
  );
};

export default TransactionList;

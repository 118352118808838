import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import text from '../../text';

function formatHebrewText(text) {
  let parts = text.split(/(\d\.\s)/).filter(part => part.trim() !== '');

  let header = parts.shift();

  let formattedText = parts.reduce((acc, part, index) => {
    if (index % 2 === 0) {
      return acc + `<p>${part.trim()}`;
    } else {
      return acc + part.trim() + `</p>`;
    }
  }, `<p>${header.trim()}</p>`);

  return formattedText;
}

const InWallSafe = ({ formData, handleChange }) => {
  const { language } = useLanguage();

  return (
    <div className="in-wall-safe">
      <h3 style={{borderBottom:'2px solid #ccc ', width:'90%'}}>{text[language].inWallSafeCommitment}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: formatHebrewText(
            text[language].commitmentText
              .replace('<user name>', formData.fullName)
              .replace('<teudat_zehut>', formData.teudatZehut)
          ),
        }}
      />
      <label className='checkbox-row'>
        <p style={{width:'100%'}}>{text[language].confirmCommitment}</p>
        <input
          type="checkbox"
          name="inWallSafeCommitment"
          checked={formData.inWallSafeCommitment}
          onChange={handleChange}
          required
        />
      </label>
    </div>
  );
};

export default InWallSafe;

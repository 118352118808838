import React, { useState } from 'react';
import { addSubscriber } from './firebase';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import close_icon from '../icons/icons8-close-60.png';
import '../styles/Subscribe.css';

const Subscribe = ({ onClose }) => {
  const { language } = useLanguage();
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleSubscribe = async () => {
    if (isCheckboxChecked && isEmailValid) {
      try {
        await addSubscriber({ email, name, mobile });
        onClose();
        alert(text[language].subscriptionSuccess);
      } catch (error) {
        console.error('Error during subscription:', error);
        alert(text[language].subscriptionError);
      }
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsEmailValid(validateEmail(event.target.value));
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        <img src={close_icon} alt="Close" className="close-icon" onClick={onClose} />
        <br />
        <h2>{text[language].joinCommunity}</h2>
        <input
          type="text"
          value={name}
          onChange={handleNameChange}
          placeholder={text[language].enterName}
        />
        <input
          type="text"
          value={mobile}
          onChange={handleMobileChange}
          placeholder={text[language].enterMobile}
        />
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder={text[language].enterEmail}
          className={isEmailValid ? '' : 'invalid'}
        />
        <label>
          {text[language].wantUpdates}
          <input
            type="checkbox"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />
        </label>
        <button onClick={handleSubscribe} disabled={!isCheckboxChecked || !isEmailValid}>
          {text[language].subscribeButton}
        </button>
      </div>
    </div>
  );
};

export default Subscribe;

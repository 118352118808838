import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import '../styles/AboutUs.css';
import { Helmet } from 'react-helmet';


const AboutUs = () => {
  const { language } = useLanguage();

  // Helper function to format the content
  const formatContent = (content) => {
    // Split the content by periods and filter out any empty strings
    const sentences = content.split('.').filter(sentence => sentence.trim() !== '');

    // Return the sentences as JSX elements, with each sentence in a new paragraph
    return sentences.map((sentence, index) => (
      <p key={index}>{sentence.trim()}.</p>
    ));
  };

  return (
    <div className="about-us">
      <Helmet>
        <title>מטווח שי לוי - אודות המטווח</title>
        <meta name="description" content="מטווח שי לוי - קרני שומרון, ביקורות אקדחים, אימונים, חידוש רישיון ועוד" />
        <meta name="keywords" content=", נרתיק לאקדח, זיג, Sig Sauer, הלקט , אקדח חדש, אקדח משומש דיקת תיקנות נשק, רענון תקופתי, מטווח , מטווח שי לוי , הכשרה ראשונית , רישיון לאקדח, גלוק, Glock, מאבטחים, חידוש רישיון, קרני שומרון" />
      </Helmet>
      <h2>{text[language].aboutUsTitle}</h2>
      <div>{formatContent(text[language].aboutUsContent)}</div>
    </div>
  );
};

export default AboutUs;

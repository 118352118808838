import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import '../styles/AccessibilityMenu.css';

const AccessibilityMenu = ({ onClose }) => {
  const { language } = useLanguage();
  const [isDarkMode, setIsDarkMode] = useState('theme' in localStorage ? localStorage.getItem('theme') === 'dark' : false);

  

  const toggleDarkMode = () => {
    if (isDarkMode) {
      document.body.classList.remove('dark-mode');
      localStorage.setItem('theme', 'light');
    } else {
      document.body.classList.add('dark-mode');
      localStorage.setItem('theme', 'dark');
    }
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`accessibility-menu open`}>
      
        <div className="menu-content">
          <h3>{text[language].accessibilityOptions}</h3>
          <div>
            <button onClick={() => { document.body.style.fontSize = 'larger'; }}>{text[language].increaseFontSize}</button>
            <button onClick={() => { document.body.style.fontSize = 'smaller'; }}>{text[language].decreaseFontSize}</button>
          </div>
          <div>
            <button onClick={toggleDarkMode}>
              {isDarkMode ? text[language].toggleBrightMode : text[language].toggleDarkMode}
            </button>
            <button onClick={() => { document.body.classList.toggle('high-contrast'); }}>{text[language].toggleHighContrast}</button>
            <button onClick={() => { document.body.classList.toggle('inverted-colors'); }}>{text[language].toggleInvertedColors}</button>
          </div>
          <button onClick={() => { 
            document.body.classList.remove('bright-mode', 'high-contrast', 'inverted-colors');
            document.body.style = '';
            setIsDarkMode(true);
            document.body.classList.add('dark-mode');
            localStorage.setItem('theme', 'dark');
          }}>{text[language].reset}</button>
          <button onClick={onClose}>{text[language].closeMenu}</button>
        </div>
      
    </div>
  );
};

export default AccessibilityMenu;

import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import '../styles/Welcome.css';
import closeIcon from '../icons/icons8-close-100.png';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import { db } from './firebase';
import { useNavigate } from 'react-router-dom';

const Welcome = ({ setActiveContent, onClose }) => {
  const { language } = useLanguage();
  const [openingTimes, setOpeningTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOpeningTimes = async () => {
      const activities = ['shootingRange', 'freeShooting', 'licenseRenewal', 'newLicense'];
      const data = {};

      try {
        for (const activity of activities) {
          const docRef = doc(db, 'openingHours', activity);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            data[activity] = docSnap.data();
          } else {
            console.error(`No document found for activity: ${activity}`);
          }
        }
        setOpeningTimes(data);
      } catch (error) {
        console.error("Error fetching opening times:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOpeningTimes();
  }, []);

  const handleNewGunner = () => {
    navigate('/license');
    onClose();
  };

  return (
    <div className="welcome-overlay" onClick={onClose}>
      <div className="welcome-popup" onClick={(e) => e.stopPropagation()}>
        {loading ? (
          <div className="spinner"></div> 
        ) : (
          <>
        <img src={closeIcon} alt="Close" className="welcome-close-icon" onClick={onClose} />
            <h2 className="welcome-title">{text[language].welcome}</h2>
            <h2 className="welcome-subtitle">{text[language].gunRangeTitle}</h2>
            <div className="welcome-training-hours">
              <h3>{text[language].firstTrainingHoursTitle}</h3>
              <p>
                {text[language].sundayToThursday}: {openingTimes.newLicense?.sundayToThursday?.join(', ')}
              </p>
              <p>
                {text[language].friday}: {openingTimes.newLicense?.fridays}
              </p>
              <button className="welcome-nav-button" onClick={() => { handleNewGunner() }}>{text[language].newLicenseTitle}</button>
            </div>

            <div className="welcome-training-hours">
              <h3>{text[language].renewalHoursTitle}</h3>
              <p>
                {text[language].sundayToThursday}: {openingTimes.licenseRenewal?.sundayToThursday?.join(', ')}
              </p>
              <p>
                {text[language].friday}: {openingTimes.licenseRenewal?.fridays}
              </p>
            </div>

            <div className="welcome-training-hours">
              <h3>{text[language].officeHoursTitle}</h3>
              <p>
                {text[language].sundayToThursday}: {openingTimes.shootingRange?.sundayToThursday}
              </p>
              <p>
                {text[language].friday}: {openingTimes.shootingRange?.fridays}
              </p>
              <p>{text[language].arrival}</p>
            </div>
          </>
        )}
      <button className="welcome-nav-button" onClick={onClose}>{text[language].close}</button>
      </div>
    </div>
  );
};

export default Welcome;

import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/UsedPistols.css';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import { Helmet } from 'react-helmet';

const UsedPistols = () => {
  const [pistols, setPistols] = useState([]);
  const { language } = useLanguage();

  useEffect(() => {
    const fetchPistols = async () => {
      try {
        const pistolsCollection = collection(db, 'usedPistols');
        const pistolsSnapshot = await getDocs(pistolsCollection);
        const pistolsData = pistolsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPistols(pistolsData);
      } catch (error) {
        console.error('Error fetching used pistols:', error);
      }
    };

    fetchPistols();
  }, []);

  return (
    <div className="used-pistols-container">
      <Helmet>
        <title>מטווח שי לוי - אקדחים משומשים</title>
        <meta name="description" content="אקדחים משומשים - מטווח שי לוי" />
        <meta name="keywords" content="בדיקת תיקנות נשק, רענון תקופתי, מטווח , מטווח שי לוי , הכשרה ראשונית , רישיון לאקדח, גלוק, Glock, מאבטחים, חידוש רישיון, קרני שומרון" />
      </Helmet>
      <h2>{text[language].usedPistolsTitle}</h2>
      <p>{text[language].usedPistolsDisclaimer}</p>
      <table className="used-pistols-table">
        <thead>
          <tr>
            <th>{text[language].manufacturer}</th>
            <th>{text[language].model}</th>
            <th>{text[language].year}</th>
            <th>{text[language].price}</th>
            <th>{text[language].comments}</th>
          </tr>
        </thead>
        <tbody>
          {pistols.map(pistol => (
            pistol.status !== 'Sold' && (
              <tr key={pistol.id}>
                <td>{pistol.manufacturer}</td>
                <td>{pistol.model}</td>
                <td>{pistol.year}</td>
                <td>{pistol.price}</td>
                <td>{pistol.comments}</td>
              </tr>
            )
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsedPistols;

import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import trainCertificate from '../pdfs/train_certification.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl,fetchSignatureFromStorage } from '../components/firebase'; // Import the upload function

const EnglishOrHebrew = (text) => {
  if (!text) return '';
  const hebrewRegex = /[\u0590-\u05FF]/;
  const englishRegex = /[a-zA-Z]/;
  if (hebrewRegex.test(text)) {
      return text;
  } else if (englishRegex.test(text)) {
      return text.split('').reverse().join('');
  } else {
      return '';
  }
};
const generateCertificate = async (formData) => {
  const existingPdfUrl = trainCertificate;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  // Load a font that supports Hebrew characters
  const fontUrl = open_sans_font;
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  // rangeID_currentYear
  const rangeId = formData.rangeId+'_'+formData.registrationDate.split(',')[0].split('.')[2];
  const date = formData.registrationDate.split(',')[0];
  const time = formData.registrationDate.split(',')[1];

  const fullName = formData.fullName || '';
  const teudatZehut = formData.teudatZehut || '';
  const phone = formData.phoneNumber || '';
  const license = formData.gunLicenseId || '';

  const model = formData.model || '';
  const menufacturer = formData.pistolManufacturer || '';
  const ammunitionCaliber = formData.ammunitionCaliber || '';
  const quantity = formData.bulletCount || '';
  const hits = formData.hits || '';
  const address = formData.city || '';
  const reverse = (str) => {
    return str.split('').reverse().join('');
  }


  let signatureUrl = formData.signatureUrl || null;
  if (!signatureUrl) {
    signatureUrl = await fetchSignatureFromStorage(rangeId);
  }

 firstPage.drawText(rangeId, { x: 250, y: 685, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText('מרכז', { x: 440, y: 595, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(date, { x: 315, y: 595, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(time, { x: 200, y: 595, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(license, { x: 100, y: 595, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });


  firstPage.drawText(teudatZehut, { x: 440, y: 515, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(fullName, { x: 315, y: 515, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(phone, { x: 200, y: 515, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(address, { x: 100, y: 515, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText((menufacturer) +' '+ (model), { x: 440, y: 427, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(ammunitionCaliber, { x: 330, y: 427, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(quantity.toString(), { x: 250, y: 427, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(hits +'/' + 20, { x: 100, y: 427, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
    


  // Add the signature
  if (signatureUrl) {
    const signatureImage = await pdfDoc.embedPng(signatureUrl);
    firstPage.drawImage(signatureImage, { x: 80, y: 150, width: 150*1.1, height: 50*1.1 });
  }

  const pdfBytes = await pdfDoc.save();
  const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'train_certificates', `train_certificate_${teudatZehut}_${fullName}_${date}`);

  return downloadUrl;
};

export default generateCertificate;

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('he'); // Default to Hebrew
  const location = useLocation(); // Get the current path

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    // Define paths where you want the language to switch to Hebrew
    const hebrewPaths = ['/register', '/quiz', '/pickup', '/guns'];

    // If the current path is in the list, set the language to Hebrew
    if (hebrewPaths.includes(location.pathname)) {
      setLanguage('he');
    }
  }, [location.pathname]); // Re-run the effect when the pathname changes

  const toggleLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('preferredLanguage', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

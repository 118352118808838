// src/components/LanguageSwitch.js

import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext.js';
import '../styles/LanguageSwitch.css';
import IL_icon from '../icons/icons8-israel-96.png';
import US_icon from '../icons/icons8-usa-96.png';
import RU_icon from '../icons/icons8-russia-96.png';

const LanguageSwitch = () => {
  const { language, toggleLanguage } = useLanguage();
  const [activeIndex, setActiveIndex] = useState(
    language === 'he' ? 0 : language === 'en' ? 1 : 2
  );

  const languages = [
    { code: 'he', icon: IL_icon, alt: 'Israel Flag' },
    { code: 'en', icon: US_icon, alt: 'US Flag' },
    { code: 'ru', icon: RU_icon, alt: 'Russia Flag' },
  ];

  const handleClick = (index) => {
    setActiveIndex(index);
    toggleLanguage(languages[index].code);
  };

  return (
    <div className="language-slider">
      {languages.map((lang, index) => (
        <div
          key={lang.code}
          className={`language-option ${
            index === activeIndex ? 'active' : ''
          }`}
          onClick={() => handleClick(index)}
        >
          <img src={lang.icon} alt={lang.alt} className="flag-icon" />
        </div>
      ))}
    </div>
  );
};

export default LanguageSwitch;

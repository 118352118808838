import React, { useState } from 'react';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';
import { db } from '../components/firebase';
import '../styles/QuizComponent.css';
import quizData from '../tests.json';
import { generateUserTestPdf } from '../FileGenerators/UserTestGen';

const QuizComponent = () => {
  const [teudatZehut, setTeudatZehut] = useState('');
  const [user, setUser] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const [quizNumber, setQuizNumber] = useState(null);
  const [quizType, setQuizType] = useState('הכשרה ראשונית');
  const [userAnswers, setUserAnswers] = useState({});
  const [quizMessage, setQuizMessage] = useState('');
  const [retakes, setRetakes] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleTeudatZehutChange = (e) => setTeudatZehut(e.target.value);

  const resetIdentification = () => {
    setTeudatZehut('');
    setUser(null);
    setQuiz(null);
    setQuizNumber(null);
    setQuizType('');
    setUserAnswers({});
    setQuizMessage('');
    setRetakes(0);
  };

  const handleStartQuiz = async () => {
    setIsLoading(true);
    try {
      // Check if the Teudat Zehut is valid
      if (teudatZehut.length !== 9) {
        alert("תעודת זהות חייבת להיות בעלת 9 ספרות");
        resetIdentification();
        return;
      }

      // Fetch user from "users" collection
      const userDocRef = doc(collection(db, 'users'), teudatZehut);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        alert("לא נמצאה התאמה לתעודת הזהות במערכת");
        resetIdentification();
        return;
      }

      // User exists; set user data
      const userData = userDocSnapshot.data();
      setUser(userData);

      // Set quiz type based on user role or other criteria
      // const registrationType = 'הכשרה ראשונית'; // Example of setting a default registration type
      // setQuizType(registrationType);

      // Get available tests for the registration type
      const availableTests = quizData.tests[quizType];
      if (!availableTests) {
        alert("לא נמצא מבחן עבור סוג הרשמה זה");
        resetIdentification();
        return;
      }

      // Select one random test
      const testKeys = Object.keys(availableTests);
      const randomTestKey = testKeys[Math.floor(Math.random() * testKeys.length)];
      const selectedTest = availableTests[randomTestKey];

      // Format the selected test's questions
      const formattedQuiz = {
        questions: Object.keys(selectedTest)
          .filter((key) => key.startsWith('q') && key.endsWith('answers'))
          .map((answersKey) => {
            const questionKey = answersKey.replace('_answers', '');
            const correctAnswerKey = `${questionKey}_answer`;

            if (
              selectedTest[questionKey] &&
              Array.isArray(selectedTest[answersKey]) &&
              typeof selectedTest[correctAnswerKey] === 'string'
            ) {
              return {
                question: selectedTest[questionKey],
                answers: selectedTest[answersKey],
                correctAnswer: selectedTest[correctAnswerKey],
                testNumber: randomTestKey.replace('test_', ''),
              };
            } else {
              console.warn(`Skipping malformed question or answers: ${questionKey}`);
              return null;
            }
          })
          .filter((q) => q !== null), // Filter out malformed questions
      };

      setQuiz(formattedQuiz);
      setQuizNumber(randomTestKey); // Set the quiz number for display
    } catch (error) {
      console.error("Error starting quiz:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRetakeQuiz = () => {
    setQuizMessage('');
    setUserAnswers({});
  };

  const handleAnswerSelect = (questionIndex, answerIndex) => {
    setUserAnswers((prev) => ({ ...prev, [questionIndex]: answerIndex }));
  };

  const handleSubmitQuiz = async () => {
    const confirmSubmit = window.confirm("יש לוודא שענית על כלל השאלות במבחן. האם את/ה בטוח שברצונך להגיש את המבחן?");

    if (!confirmSubmit) {
      return;
    }

    try {
      const correctAnswers = quiz.questions.filter(
        (question, index) => userAnswers[index] === question.answers.indexOf(question.correctAnswer)
      ).length;

      const totalQuestions = quiz.questions.length;
      const passed = correctAnswers >= totalQuestions - 5;
      let resultString = `${correctAnswers}T${totalQuestions}${passed ? 'P' : 'F'}`;

      

      const message = passed
        ? `תודה שמילאת את המבחן - עברת בהצלחה! ענית על ${correctAnswers} שאלות נכון מתוך ${totalQuestions} שאלות`
        : `תודה שמילאת את המבחן - הנך נדרש לבצע את המבחן שוב. ענית על ${correctAnswers} שאלות נכון מתוך ${totalQuestions} שאלות`;

      setQuizMessage(message);

      // Update quiz results in "tests" collection
      const today = new Date();
      const dateKey = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

      const testResult = {
        teudatZehut: user.teudatZehut,
        fullName: user.fullName,
        quizResult: resultString,
        quizType: quizType,
        date: dateKey,
        testNumber: quizNumber,
        retakes: retakes,
      };

      const testDocRef = doc(collection(db, 'tests'), dateKey);
      const docSnapshot = await getDoc(testDocRef);

      let updatedData;
      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        updatedData = {
          ...existingData,
          [user.teudatZehut]: testResult, // Add new test result under user ID
        };
      } else {
        updatedData = {
          [user.teudatZehut]: testResult, // Create a new document if it doesn't exist
        };
      }

      await setDoc(testDocRef, updatedData, { merge: true });

      // Generate the PDF and save it
      await generateUserTestPdf(user,testResult, quiz, userAnswers);

      if (!passed && retakes < 2) {
        setRetakes(retakes + 1);
      }
    } catch (error) {
      console.error("Error submitting quiz:", error);
    }
  };

  if (!user) {
    return (
      <div className="quiz-container">
        <div className="quiz-header">
          <h3>להתחלת הבחינה יש להכניס תעודת זהות</h3>
          <input type="number" placeholder='123456789' value={teudatZehut} onChange={handleTeudatZehutChange} />
          <select value={quizType} onChange={(e) => setQuizType(e.target.value)}>
            <option value="הכשרה ראשונית">הכשרה ראשונית</option>
            <option value="הכשרה ארגונית">הכשרה ארגונית</option>
          </select>
          {isLoading ? <div>טוען...</div> : <button className="quiz-submit-button" onClick={handleStartQuiz}>התחלה</button>}
        </div>
      </div>
    );
  }

  if (quizMessage) {
    return (
      <div className="quiz-result">
        <p>{quizMessage}</p>
        {!quizMessage.includes("עברת בהצלחה!") && retakes < 3 && (
          <button className="quiz-submit-button" onClick={handleRetakeQuiz}>ביצוע המבחן פעם נוספת</button>
        )}
        {retakes >= 3 && <p>נכשלת במבחן 3 פעמים ואינך רשאי להיבחן שוב.</p>}
        <button className="quiz-submit-button" onClick={() => window.location.href = '/'}>חזור לעמוד הראשי</button>
      </div>
    );
  }

  return (
    <div className="quiz-container">
      <div className="quiz-header">
        <h3>שלום {user.fullName}</h3>
        {quizNumber && <p>מבחן {quizType} מספר: {quizNumber.replace('test_', '')} </p>}
        <p>* מבחן זה נכתב בלשון זכר מטעמי נוחות בלבד, אך מיועד לנשים וגברים כאחד.</p>
        <p>
          מבחן זה נערך על פי החובה הקבועה בתקנות כלי ירייה (הכשרה) 2023
          עליך לענות על התשובה הנכונה ביותר מבין ארבע האפשרויות
          אין להיעזר באדם אחר כולל המדריך
          בהצלחה!
        </p>
      </div>
      {quiz ? (
        <>
          {quiz.questions.map((question, questionIndex) => (
            <div key={questionIndex}>
              <h4>{questionIndex + 1}. {question.question}</h4>
              <ul className="quiz-questions">
                {question.answers.map((answer, answerIndex) => (
                  <li key={answerIndex}>
                    <label>
                      <input
                        type="radio"
                        name={`question-${questionIndex}`}
                        onChange={() => handleAnswerSelect(questionIndex, answerIndex)}
                        checked={userAnswers[questionIndex] === answerIndex}
                        required
                      />
                      {answer}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <button className="quiz-submit-button" onClick={handleSubmitQuiz}>הגשת מבחן</button>
        </>
      ) : (
        <div>Loading quiz...</div>
      )}
    </div>
  );
};

export default QuizComponent;

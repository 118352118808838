import emailjs from 'emailjs-com';

const sendEmail = async (emailParams) => {
  alert(`Email sent to ${emailParams.to_email}\nSubject: ${emailParams.subject}\nMessage: ${emailParams.message}`);

  // if (isDevMode) {
  //   alert(`Email to ${emailParams.to_email}\nSubject: ${emailParams.subject}\nMessage: ${emailParams.message}`);
  // } else {
    //alert(`Email sent to ${emailParams.to_email}\nSubject: ${emailParams.subject}\nMessage: ${emailParams.message}`);
    emailjs.send('subscribers-kravBM', 'shai_subscribers', emailParams, 'cZhzGdYGZLShYtlbV')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      }, (error) => {
        console.error('FAILED...', error);
      });
  // }
};

export default sendEmail;

import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/ManagePistols.css';
import { EntryType } from 'pdf-lib/cjs/core/structures/PDFCrossRefStream';

const ManagePistols = () => {
  const [pistols, setPistols] = useState([]);
  const [newPistol, setNewPistol] = useState({
    manufacturer: '',
    model: '',
    year: '',
    price: '',
    comments: '',
  });
  const [editedPistols, setEditedPistols] = useState({});
  const [markingAsSold, setMarkingAsSold] = useState(null);
  const [isAddPistol, setIsAddPistol] = useState(false);
  const [buyerInfo, setBuyerInfo] = useState({
    name: '',
    id: '',
    phone: '',
    address: '',
    priceSold: '',
  });

  useEffect(() => {
    const fetchPistols = async () => {
      try {
        const pistolsCollection = collection(db, 'usedPistols');
        const pistolsSnapshot = await getDocs(pistolsCollection);
        const pistolsData = pistolsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPistols(pistolsData);
      } catch (error) {
        console.error('Error fetching used pistols:', error);
      }
    };

    fetchPistols();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPistol(prevState => ({ ...prevState, [name]: value }));
  };

  const handleEditChange = (id, field, value) => {
    setEditedPistols(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: value,
      },
    }));
  };

  const handleBuyerInfoChange = (e) => {
    const { name, value } = e.target;
    setBuyerInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const addPistol = async () => {
    const pistolDoc = doc(collection(db, 'usedPistols'));
    await setDoc(pistolDoc, newPistol);
    setPistols(prevState => [...prevState, { id: pistolDoc.id, ...newPistol }]);
    setNewPistol({ manufacturer: '', model: '', year: '', price: '', comments: '' });
  };

  const saveChanges = async () => {
    try {
      const updatePromises = Object.keys(editedPistols).map(async id => {
        const pistolDoc = doc(db, 'usedPistols', id);
        await updateDoc(pistolDoc, editedPistols[id]);
        setPistols(prevState => prevState.map(pistol => pistol.id === id ? { ...pistol, ...editedPistols[id] } : pistol));
      });
      await Promise.all(updatePromises);
      setEditedPistols({});
    } catch (error) {
      console.error('Error saving changes:', error);
    }
    setIsAddPistol(false);
  };

  const markAsSold = async () => {
    if (markingAsSold) {
      const pistolDoc = doc(db, 'usedPistols', markingAsSold);
      const soldData = { status: 'Sold', buyer: buyerInfo };
      await updateDoc(pistolDoc, soldData);
      setPistols(prevState => prevState.map(pistol => pistol.id === markingAsSold ? { ...pistol, ...soldData } : pistol));
      setMarkingAsSold(null);
      setBuyerInfo({
        name: '',
        id: '',
        phone: '',
        address: '',
        priceSold: '',
      });

    }
  };

  const cancelMarkAsSold = () => {
    setMarkingAsSold(null);
    setBuyerInfo({
      name: '',
      id: '',
      phone: '',
      address: '',
      priceSold: '',
    });
  };

  return (
    <div className="manage-pistols-container">
      <h2>נהל אקדחים משומשים</h2>
      <table className="manage-pistols-table">
        <thead>
          <tr>
            <th>יצרן</th>
            <th>דגם</th>
            <th>שנה</th>
            <th>מחיר</th>
            <th>הערות</th>
            <th>פעולות</th>
          </tr>
        </thead>
        <tbody>
          {pistols.map(pistol => (
            pistol.status !== 'Sold' ? (
            <tr key={pistol.id}>
              <td><input type="text" value={editedPistols[pistol.id]?.manufacturer || pistol.manufacturer} onChange={(e) => handleEditChange(pistol.id, 'manufacturer', e.target.value)} /></td>
              <td><input type="text" value={editedPistols[pistol.id]?.model || pistol.model} onChange={(e) => handleEditChange(pistol.id, 'model', e.target.value)} /></td>
              <td><input type="text" value={editedPistols[pistol.id]?.year || pistol.year} onChange={(e) => handleEditChange(pistol.id, 'year', e.target.value)} /></td>
              <td><input type="text" value={editedPistols[pistol.id]?.price || pistol.price} onChange={(e) => handleEditChange(pistol.id, 'price', e.target.value)} /></td>
              <td><input type="text" value={editedPistols[pistol.id]?.comments || pistol.comments} onChange={(e) => handleEditChange(pistol.id, 'comments', e.target.value)} /></td>
              <td>
                <button onClick={() => setMarkingAsSold(pistol.id)}>סמן כנמכר</button>
              </td>
            </tr>
            ) : (
            <tr key={pistol.id}>
              <td>{pistol.manufacturer}</td>
              <td>{pistol.model}</td>
              <td>{pistol.year}</td>
              <td>{pistol.price}</td>
              <td>{pistol.comments}</td>
              <td>
                <p>נמכר</p>
              </td>
            </tr>
            )
          ))}
        </tbody>
      </table>
      <div className="add-pistol-form">
        <button onClick={saveChanges}>שמור שינויים</button>
    </div>
      {markingAsSold && (
          <div className="mark-as-sold-form">
          <h3>יש לעדכן את פרטי הקונה</h3>
          <input type="text" name="name" placeholder="שם הקונה" value={buyerInfo.name} onChange={handleBuyerInfoChange} />
          <input type="text" name="id" placeholder="ת.ז הקונה" value={buyerInfo.id} onChange={handleBuyerInfoChange} />
          <input type="text" name="phone" placeholder="טלפון הקונה" value={buyerInfo.phone} onChange={handleBuyerInfoChange} />
          <input type="text" name="address" placeholder="כתובת הקונה" value={buyerInfo.address} onChange={handleBuyerInfoChange} />
          <input type="text" name="priceSold" placeholder="מחיר שנמכר" value={buyerInfo.priceSold} onChange={handleBuyerInfoChange} />
          <button onClick={markAsSold}>אשר מכירה</button>
          <button onClick={cancelMarkAsSold}>בטל</button>
        </div>
      )}
      {isAddPistol ? (
        <div className="add-pistol-form">
        <h3>הוסף אקדח משומש</h3>
        <input type="text" name="manufacturer" placeholder="יצרן" value={newPistol.manufacturer} onChange={handleInputChange} />
        <input type="text" name="model" placeholder="דגם" value={newPistol.model} onChange={handleInputChange} />
        <input type="text" name="year" placeholder="שנה" value={newPistol.year} onChange={handleInputChange} />
        <input type="text" name="price" placeholder="מחיר" value={newPistol.price} onChange={handleInputChange} />
        <input type="text" name="comments" placeholder="הערות" value={newPistol.comments} onChange={handleInputChange} />
        <button onClick={addPistol}>הוסף רישום</button>
      </div>
      ) : (
        <div className="add-pistol-form">
            <button onClick={() => setIsAddPistol(true)}>הוסף אקדח משומש</button>
        </div>
      )}
    </div>
  );
};

export default ManagePistols;

import { PDFDocument, rgb } from 'pdf-lib'; 
import fontkit from '@pdf-lib/fontkit';
import { doc, setDoc } from 'firebase/firestore';
import { db, uploadPdfAndGetUrl } from '../components/firebase'; // Import the upload function
import openSansFont from '../OpenSans-VariableFont_wdth,wght.ttf';

export const generateUserTestPdf = async (client,results, quiz, userAnswers) => {
  if (!client || !client.quizResult) {
    alert("שגיאה בייצור המבחן - נא לפנות למדריך וליידע אותו בתוצאות המבחן");
    return;
  }

  const pdfDoc = await PDFDocument.create();

  const reverseText = (text) => text.split('').reverse().join('');
  // Load the custom font
  pdfDoc.registerFontkit(fontkit);
  const fontBytes = await fetch(openSansFont).then(res => res.arrayBuffer());
  const customFont = await pdfDoc.embedFont(fontBytes);

  const today = new Date();
  const dateKey = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  const fontSize = 9;
  const testResult = client.quizResult.includes('F') ? 'נכשל' : 'עבר';
  
  const correctAnswers = reverseText(client.quizResult.split('T')[0] + '/20');
  const randomNumber = Math.floor(Math.random() * 5) + 1;

  let page = pdfDoc.addPage([600, 750]);
  let { width, height } = page.getSize();
  const addHeader = () => {
    page.drawText(`${results.quizResult}`, { x: width - 400, y: height - 20, size: fontSize, font: customFont }); 
    page.drawText(`תאריך: ${reverseText(client.registrationDate)}`, { x: width - 150, y: height - 20, size: fontSize, font: customFont });
    page.drawText(`מספר מבחן: ${results.testNumber }`, { x: width - 250, y: height - 20, size: fontSize, font: customFont });
    page.drawText(`פרטי הנבחן`, { x: width - 150, y: height - 50, size: 18, font: customFont, color: rgb(0, 0, 0) });
    page.drawText(`שם מלא: ${client.fullName}`, { x: width - 150, y: height - 80, size: fontSize, font: customFont });
    page.drawText(`תעודת זהות: ${reverseText(client.teudatZehut)}`, { x: width - 150, y: height - 110, size: fontSize, font: customFont });
    page.drawText(`מספר טלפון: ${reverseText(client.phoneNumber)}`, { x: width - 150, y: height - 140, size: fontSize, font: customFont });
  };

  addHeader();
  
  let yPosition = height - 230;

  quiz.questions.forEach((question, index) => {
    const userAnswer = userAnswers[index];
    const userAnswerText = question.answers[userAnswer];

    // Check if there's enough space on the current page; if not, create a new page
    if (yPosition < 100) {
      page = pdfDoc.addPage([600, 750]);
      ({ width, height } = page.getSize());
      yPosition = height - 50;
    }

    page.drawText(`${reverseText((index + 1).toString())}. ${question.question}`, { x: 50, y: yPosition, size: fontSize, font: customFont });
    yPosition -= 20;
    page.drawText(`תשובה נבחרה: ${userAnswerText}`, { x: 70, y: yPosition, size: fontSize, font: customFont });
    yPosition -= 20;
    page.drawText(`תשובה נכונה: ${question.correctAnswer}`, { x: 70, y: yPosition, size: fontSize, font: customFont });
    yPosition -= 40;
  });

  const pdfBytes = await pdfDoc.save();
  const pdfFileName = `quiz_${client.rangeId}_${client.teudatZehut}.pdf`;

  // Save the generated PDF to Firebase Storage and get the URL
  const pdfUrl = await uploadPdfAndGetUrl(pdfBytes, 'tests', pdfFileName);
  // Save the generated PDF URL to the user's test record in Firestore (in the 'tests' collection)
  await setDoc(doc(db, 'tests', dateKey), {
    [client.teudatZehut]: {
      phoneNumber:client.phoneNumber,
      quizFileUrl: pdfUrl // Update quizFileUrl in the tests collection
    }
  }, { merge: true });
};

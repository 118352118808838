import React, { useState } from 'react';
import ShooterLog from './ShooterLog';
import GunCollection from './GunCollection';
import '../styles/Ledger.css';

const Ledger = () => {
  const [selectedTab, setSelectedTab] = useState('ledger'); // Initialize with 'ledger' tab

  return (
    <div className="ledger-container">
      <div className="filter-header">
        <div className="tab-header">
          <button onClick={() => setSelectedTab('ledger')} className={selectedTab === 'ledger' ? 'active' : ''}>יומן יורים</button>
          <button onClick={() => setSelectedTab('firearms')} className={selectedTab === 'firearms' ? 'active' : ''}>איסוף אקדח</button>
        </div>
      </div>

      {selectedTab === 'ledger' && <ShooterLog />}
      {selectedTab === 'firearms' && <GunCollection />}
    </div>
  );
};

export default Ledger;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import '../styles/WeaponReviews.css';
import { Helmet } from 'react-helmet';

import youtubeIcon from '../icons/icons8-youtube-100.png';
import { logEvent, analytics, db } from './firebase';
import { collection, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';

const API_KEY = 'AIzaSyCEUqILQKZPYGUICbCdBZJarMvaF67VstI';
const CHANNEL_ID = 'UC-sW4_BaoZG8XHPpUwAMyPg';

const WeaponReviews = () => {
  const { language } = useLanguage();
  const [newestVideos, setNewestVideos] = useState([]);
  const [topViewedVideos, setTopViewedVideos] = useState([]);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    fetchNewestVideos();
    fetchTopViewedVideos();
  }, []);

  const updateCounter = async (event) => {
    const eventRef = doc(collection(db, 'analytics'), event);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      await updateDoc(eventRef, { counter: increment(1) });
    } else {
      await setDoc(eventRef, { event, counter: 1, timestamp: new Date() });
    }
  };

  const handleYoutubeClick = async () => {
    logEvent(analytics, 'youtube_channel_visited');
    await updateCounter('youtube_channel_visited');
  };

  const handleYouTubePlay = async () => {
    logEvent(analytics, 'onsite_youtube_played');
    await updateCounter('onsite_youtube_played');
  };

  const fetchNewestVideos = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=3`
      );
      setNewestVideos(response.data.items);
    } catch (error) {
      console.error('Error fetching newest videos:', error);
      setApiError(true);
    }
  };

  const fetchTopViewedVideos = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=viewCount&maxResults=3`
      );
      setTopViewedVideos(response.data.items);
    } catch (error) {
      console.error('Error fetching top viewed videos:', error);
      setApiError(true);
    }
  };

  if (apiError) {
    return (
      <div className="weapon-reviews">
        <h2>{text[language].weaponReviewsTitle}</h2>
        <p>{text[language].youtubeError}</p>
      </div>
    );
  }

  return (
    <div className="weapon-reviews">
      <Helmet>
        <title>מטווח שי לוי - סרטונים וביקורות על נשקים</title>
        <meta name="description" content="מטווח שי לוי - קרני שומרון, ביקורות אקדחים, אימונים, חידוש רישיון ועוד" />
        <meta name="keywords" content=", נרתיק לאקדח, זיג, Sig Sauer, הלקט , אקדח חדש, אקדח משומש דיקת תיקנות נשק, רענון תקופתי, מטווח , מטווח שי לוי , הכשרה ראשונית , רישיון לאקדח, גלוק, Glock, מאבטחים, חידוש רישיון, קרני שומרון" />
      </Helmet>
      <h3>{text[language].newestVideos}</h3>
      <div className="videos">
        {newestVideos.map((video) => (
          <div key={video.id.videoId} className="video">
            <div className="iframe-container" onClick={handleYouTubePlay}>
              <iframe
                width="100%"
                height="200"
                src={`https://www.youtube.com/embed/${video.id.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={video.snippet.title}
              ></iframe>
            </div>
            <p>{video.snippet.title}</p>
          </div>
        ))}
      </div>

      <h3>{text[language].topViewedVideos}</h3>
      <div className="videos">
        {topViewedVideos.map((video) => (
          <div key={video.id.videoId} className="video">
            <div className="iframe-container" onClick={handleYouTubePlay}>
              <iframe
                width="100%"
                height="200"
                src={`https://www.youtube.com/embed/${video.id.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={video.snippet.title}
              ></iframe>
            </div>
            <p>{video.snippet.title}</p>
          </div>
        ))}
      </div>

      <div className="youtube-banner">
        <a href={`https://www.youtube.com/channel/${CHANNEL_ID}?sub_confirmation=1`}
          target="_blank" 
          rel="noopener noreferrer"
          onClick={handleYoutubeClick}>
          <p>{text[language].visitYoutubeChannel}</p>
          <img src={youtubeIcon} alt="YouTube" className="youtube-icon" />
        </a>
      </div>
    </div>
  );
};

export default WeaponReviews;

import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import AmmoSale from '../pdfs/ammunition_sale.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl } from '../components/firebase'; // Import the upload function


const generateAmmoSale = async (formData) => {
  const existingPdfUrl = AmmoSale;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  // Load a font that supports Hebrew characters
  const fontUrl = open_sans_font;
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  // rangeID_currentYear
  const rangeId = formData.rangeId+'_'+formData.registrationDate.split(',')[0].split('.')[2];
  const date = formData.registrationDate.split(',')[0];
  const time = formData.registrationDate.split(',')[1];

  const fullName = formData.fullName || 'שם משתמש';
  const teudatZehut = formData.teudatZehut || '123456789';
  const address = formData.city + ', ' + formData.street + ' ' + formData.homeNumber || '';
  const phone = formData.phoneNumber || '123456789';

  const caliber = formData.caliber || '9mm';
  const quantity = formData.bulletCount || '50';

  const signatureUrl = formData.signatureUrl || null;

 firstPage.drawText(rangeId, { x: 100, y: 700, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(date, { x: 200, y: 640, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(time, { x: 200, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(fullName, { x: 440, y: 540, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 315, y: 540, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(address, { x: 200, y: 540, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(phone, { x: 100, y: 540, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(caliber, { x: 330, y: 430, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(quantity.toString(), { x: 250, y: 430, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
    


  // Add the signature
  if (signatureUrl) {
    const signatureImage = await pdfDoc.embedPng(signatureUrl);
    firstPage.drawImage(signatureImage, { x: 80, y: 150, width: 150*1.1, height: 50*1.1 });
  }

  const pdfBytes = await pdfDoc.save();
  const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'ammos', `ammo_${teudatZehut}_${fullName}_${date}`);

  return downloadUrl;
};

export default generateAmmoSale;

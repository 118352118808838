import React, { useState, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import '../styles/Transaction.css';
import { doc, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/SignaturePad.css';
import generateTransaction from '../FileGenerators/TransactionGen';

const Transaction = ({ onCancel, client, onGenerate }) => {
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [advance, setAdvance] = useState(client.transaction?.advancePayment || 0);
  const [sellerSignature, setSellerSignature] = useState(null);
  const [buyerSignature, setBuyerSignature] = useState(null);

  const sellerSignatureRef = useRef(null);
  const buyerSignatureRef = useRef(null);

  useEffect(() => {
    if (client && client.transaction && client.transaction.transactionData) {
      setTransactionData(client.transaction.transactionData);
    } else {
      setTransactionData([
        { itemName: 'הכשרה ותחמושת', description: 'הכשרה ראשונית ותחמושת לאימון', price: '500', supplied: true },
        { itemName: 'אקדח', description: '', price: '', supplied: false },
        { itemName: 'מחסנית', description: '', price: '', supplied: false },
        { itemName: 'תחמושת אישית', description: client.bulletCount || '50', price: (client.bulletCount / 50) * 130 || '', supplied: false },
        { itemName: 'כספת', description: '', price: '', supplied: false },
        { itemName: 'נרתיק', description: '', price: '', supplied: false },
        { itemName: 'שרוך אבטחה', description: '', price: '', supplied: false },
        { itemName: 'תרסיס פלפל', description: '', price: '', supplied: false },
        { itemName: 'חומרי ניקוי', description: '', price: '', supplied: false },
        { itemName: '', description: '', price: '', supplied: false },
      ]);
    }
  }, [client]);

  const handleInputChange = (index, field, value) => {
    if (field === 'advance') {
      setAdvance(Number(value));
    } else {
      const newData = [...transactionData];
      if (newData[index]) {
        if (field === 'itemName' && value !== 'אחר') {
          // Reset the manual input when a predefined option is selected
          newData[index]['description'] = '';
        }
        newData[index][field] = value;
        setTransactionData(newData);
      }
    }
  };

  const handleAddItem = () => {
    setTransactionData([
      ...transactionData,
      { itemName: 'אחר', description: '', price: '', supplied: false }
    ]);
  };

  const getTotal = () => {
    const total = transactionData.reduce((total, item) => {
      return total + (Number(item.price) || 0);
    }, 0);
    return total - advance;
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const transactionId = `${client.teudatZehut}_${client.rangeId}`;
      const transactionDocRef = doc(db, 'transactions', transactionId);
  
      // Merge client data with transaction data for saving
      const transactionPayload = {
        ...client,  // Include all client data
        transactionData: transactionData || [],
        advancePayment: advance || 0,
        remainingAmount: getTotal(),
        createdAt: new Date().toISOString(),
        sellerSignature: sellerSignature,
        buyerSignature: buyerSignature,
      };
  
      // Save the transaction to Firestore
      await setDoc(transactionDocRef, transactionPayload);
  
      // Generate and retrieve the transaction PDF URL
      const transactionUrl = await generateTransaction(transactionPayload);
  
      // Call the onGenerate function with the transaction URL to update the parent component or UI
      onGenerate(transactionUrl);
    } catch (error) {
      console.error('Error saving transaction:', error);
    }
    setLoading(false);
  };
  

  const clearSellerSignature = () => {
    sellerSignatureRef.current.clear();
    setSellerSignature(null);
  };

  const clearBuyerSignature = () => {
    buyerSignatureRef.current.clear();
    setBuyerSignature(null);
  };

  return (
    <div className="transaction-container">
      <table className="transaction-table">
        <thead>
          <tr>
            <th>פריט</th>
            <th>תיאור/כמות</th>
            <th>מחיר</th>
            <th>סופק</th>
          </tr>
        </thead>
        <tbody>
          {transactionData.map((item, index) => (
            <tr key={index}>
              <td>
                {/* Dropdown for itemName */}
                <select
                  value={item.itemName}
                  onChange={(e) => handleInputChange(index, 'itemName', e.target.value)}
                >
                  <option value="הכשרה ותחמושת">הכשרה ותחמושת</option>
                  <option value="אקדח">אקדח</option>
                  <option value="מחסנית">מחסנית</option>
                  <option value="תחמושת אישית">תחמושת אישית</option>
                  <option value="כספת">כספת</option>
                  <option value="נרתיק">נרתיק</option>
                  <option value="שרוך אבטחה">שרוך אבטחה</option>
                  <option value="חומרי ניקוי">חומרי ניקוי</option>
                  <option value="תרסיס פלפל">תרסיס פלפל</option>
                  <option value="אחר">אחר</option>
                  <option value=''>שורה ריקה</option>
                </select>
                {/* Conditionally show input if "אחר" is selected */}
                {item.itemName === 'אחר' && (
                  <input
                    type="text"
                    value={item.description}
                    placeholder="הכנס פריט ידני"
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                  />
                )}
              </td>
              <td>
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                  disabled={item.itemName === 'אחר'} // Disable description input when "אחר" is selected
                />
              </td>
              <td>
                <input
                  type="number"
                  value={item.price}
                  onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={item.supplied}
                  onChange={(e) => handleInputChange(index, 'supplied', e.target.checked)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="add-item-button" onClick={handleAddItem}>הוסף פריט</button>

      <div className="amount-summary">
        <label>
          מקדמה:
          <input
            type="number"
            value={advance}
            onChange={(e) => handleInputChange(-1, 'advance', e.target.value)}
          />
        </label>
        <label>
          יתרה לתשלום:
          <input type="text" value={getTotal()} readOnly />
        </label>
      </div>

      <div className="signature-section">
        <div className="signature-pad">
          <h4>חתימת מוכר</h4>
          <SignatureCanvas
            ref={sellerSignatureRef}
            penColor="#3c6eb4"
            canvasProps={{ className: 'sigCanvas' }}
            onEnd={() => setSellerSignature(sellerSignatureRef.current.toDataURL())}
          />
          <button onClick={clearSellerSignature}>נקה חתימה</button>
        </div>
        <div className="signature-pad">
          <h4>חתימת קונה</h4>
          <SignatureCanvas
            ref={buyerSignatureRef}
            penColor="#3c6eb4"
            canvasProps={{ className: 'sigCanvas' }}
            onEnd={() => setBuyerSignature(buyerSignatureRef.current.toDataURL())}
          />
          <button onClick={clearBuyerSignature}>נקה חתימה</button>
        </div>
      </div>

      <div className="transaction-actions">
        <button className="cancel-button" onClick={onCancel}>
          בטל
        </button>
        {loading ? (
          <button className="loading-spinner-small"></button>
        ) : (
          <button className="generate-button" onClick={handleGenerate}>
            סכם עסקה
          </button>
        )}
      </div>
    </div>
  );
};

export default Transaction;


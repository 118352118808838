// src/components/Unsubscribe.js

import React, { useState } from 'react';
import { unsubscribe } from './firebase';
import '../styles/Unsubscribe.css';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';

const Unsubscribe = () => {
  const { language } = useLanguage();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleUnsubscribe = async () => {
    const result = await unsubscribe(email);
    if (result === 'success') {
      setMessage(text[language].unsubscribeSuccess);
    } else if (result === 'not_found') {
      setMessage(text[language].unsubscribeNotFound);
    } else {
      setMessage(text[language].unsubscribeFail);
    }
  };

  return (
    <div className="unsubscribe-container">
      <h2>{text[language].unsubscribeTitle}</h2>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={text[language].enterEmail}
      />
      <button onClick={handleUnsubscribe}>{text[language].unsubscribeButton}</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Unsubscribe;

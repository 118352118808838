import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import '../styles/Content.css';
import aboutUsImage from '../images/about-us-2.jpg';
import newLicenseImage from '../images/new-license.png';
import ourGunsImage from '../images/our-guns.jpg';
import weaponReviewsImage from '../images/gun-reviews.png';

const Content = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="content">
      <div className="catalog-grid">
        <div className="catalog-item" onClick={() => handleNavigation('/about')}>
          <img src={aboutUsImage} alt="About Us" />
          <div className="catalog-text">
            <h2>{text[language].aboutUsTitle}</h2>
            <p>{text[language].aboutUsDescription}</p>
          </div>
        </div>
        <div className="catalog-item" onClick={() => handleNavigation('/license')}>
          <img src={newLicenseImage} alt="New License" />
          <div className="catalog-text">
            <h2>{text[language].newLicenseTitle}</h2>
            <p>{text[language].newLicenseDescription}</p>
          </div>
        </div>
        <div className="catalog-item" onClick={() => handleNavigation('/guns')}>
          <img src={ourGunsImage} alt="Our Guns" />
          <div className="catalog-text">
            <h2>{text[language].ourGunsTitle}</h2>
            <p>{text[language].ourGunsDescription}</p>
          </div>
        </div>
        <div className="catalog-item" onClick={() => handleNavigation('/weapon-reviews')}>
          <img src={weaponReviewsImage} alt="Weapon Reviews" />
          <div className="catalog-text">
            <h2>{text[language].weaponReviewsTitle}</h2>
            <p>{text[language].weaponReviewsDescription}</p>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Content;

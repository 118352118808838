import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import GuardsCertificate from '../pdfs/services_refresher-training-approval-for-guards.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl } from '../components/firebase'; // Import the upload function

const generateGuardCertificate = async (formData) => {
  const existingPdfUrl = GuardsCertificate;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  // Load a font that supports Hebrew characters
  const fontUrl = open_sans_font;
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const passed = formData.passed || false;

  const fullName = formData.fullName || 'שם משתמש';
  const teudatZehut = formData.teudatZehut || '123456789';
  const currentDate = formData.registrationDate || '';
  const signatureUrl = formData.signatureUrl || null;
  const gunLicenseId = formData.gunLicenseId || '';
  const street = formData.street || '';
  const houseNumber = formData.homeNumber || '';
  const city = formData.city || '';
  const zipCode = formData.zip || '';
  const phone = formData.phoneNumber || '';
  const email = formData.email || '';

  // First Part
  firstPage.drawText(gunLicenseId, { x: 225, y: 690, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(fullName, { x: 380, y: 650, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 220, y: 650, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(street, { x: 470, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(houseNumber, { x: 420, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(city, { x: 340, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(zipCode, { x: 290, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(email, { x: 190, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(phone, { x: 100, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText('שי לוי, קרני שומרון', { x: 605, y: 584, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText('048900029', { x: 100, y: 605, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });


  firstPage.drawText(currentDate, { x: 340, y: 580, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
    
  firstPage.drawText('V', { x: 485, y: 578, size: 14, font: hebrewFont, color: rgb(0, 0, 0.5) });
  firstPage.drawText('V', { x: 485, y: 525, size: 14, font: hebrewFont, color: rgb(0, 0, 0.5) });

  // Second Part
  firstPage.drawText(gunLicenseId, { x: 190, y: 730, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText("מרכז", { x: 270, y: 360, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(fullName, { x: 380, y: 335, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 240, y: 335, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(street, { x: 470, y: 315, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(houseNumber, { x: 420, y: 315, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(city, { x: 340, y: 320, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(zipCode, { x: 290, y: 315, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(email, { x: 190, y: 315, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(phone, { x: 100, y: 315, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText('שי לוי, קרני שומרון', { x: 340, y: 290, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText('048900029', { x: 100, y: 290, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });


  firstPage.drawText(currentDate, { x: 340, y: 262, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
    
  firstPage.drawText('V', { x: 485, y: 260, size: 14, font: hebrewFont, color: rgb(0, 0, 0.5) });
  firstPage.drawText('V', { x: 485, y: 215, size: 14, font: hebrewFont, color: rgb(0, 0, 0.5) });



  

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `trainCertificate_${teudatZehut}_${fullName}_${currentDate}.pdf`;
  downloadLink.click();
  // const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'guard_certificates', `guard_certificate_${teudatZehut}`);

  // return downloadUrl;
};

export default generateGuardCertificate;

import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/TestResults.css';

const TestResults = () => {
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  });
  const [selectedRegistrationType, setSelectedRegistrationType] = useState('הכל');
  const [tests, setTests] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const registrationTypes = ['הכל', 'הכשרה ראשונית', 'הכשרה ארגונית'];

  const fetchTestResults = async () => {
    setIsLoading(true);

    // Reference the document in the 'tests' collection using the selected date as the key
    const testDocRef = doc(collection(db, 'tests'), selectedDate);
    const docSnapshot = await getDoc(testDocRef);

    if (!docSnapshot.exists()) {
      console.log("No test results found for the selected date.");
      setTests([]);
      setIsLoading(false);
      return;
    }

    const testResults = docSnapshot.data(); // Data structure: { teudatZehut1: { ...data }, teudatZehut2: { ...data }, ... }
    let allTests = Object.values(testResults).map((data) => ({ ...data }));

    // Filter by registration type if needed
    if (selectedRegistrationType !== 'הכל') {
      allTests = allTests.filter(test => test.quizType === selectedRegistrationType);
    }

    setTests(allTests);
    setIsLoading(false);
  };

  const fetchAvailableDates = async () => {
    const docsSnapshot = await getDocs(collection(db, 'tests'));
    const dates = [];
    docsSnapshot.forEach(doc => {
      dates.push(doc.id);  // Document ID is the dateKey
    });
    setAvailableDates(dates);
  };

  useEffect(() => {
    fetchAvailableDates();
  }, []);

  useEffect(() => {
    fetchTestResults();
  }, [selectedDate, selectedRegistrationType]);

  const renderTable = (tests) => (
    <div className="test-results-container">
      <h3 className="test-results-heading">תוצאות מבחנים</h3>
      <table className="test-results-table">
        <thead>
          <tr>
            <th>#</th>
            <th>סוג</th>
            <th>מבחן</th>
            <th>שם</th>
            <th>תעודת זהות</th>
            <th>נייד</th>
            <th>תוצאות</th>
            <th>קובץ</th>
            <th>מבחן חוזר</th>
          </tr>
        </thead>
        <tbody>
          {tests.map((test, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{test.quizType}</td>
              <td>{test.testNumber ? test.testNumber.split('_')[1] : 'N/A'}</td>
              <td>{test.fullName}</td>
              <td>{test.teudatZehut}</td>
              <td>{test.phoneNumber || 'N/A'}</td>
              <td style={{ backgroundColor: test.quizResult.includes('P') ? '#4CAF50' : test.quizResult.includes('F') ? '#e53935' : 'inherit' }}>
                {test.quizResult ? test.quizResult.split('_')[0] : 'טרם נבחן'}
              </td>
              <td>
                {test.quizFileUrl ? (
                 <a href={test.quizFileUrl || '#'} target="_blank" rel="noreferrer">קובץ</a> 
                ) : (
                  'לא קיים'
                )}
                </td>
              <td>{test.retakes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <div className="test-results-header">
        <h2>תוצאות מבחנים</h2>
        <div className="test-results-row">
          <label htmlFor="date-select">בחר תאריך:</label>
          <select
            id="date-select"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          >
            {availableDates.map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
          
          <label htmlFor="type-select">סוג הרשמה:</label>
          <select
            id="type-select"
            value={selectedRegistrationType}
            onChange={(e) => setSelectedRegistrationType(e.target.value)}
          >
            {registrationTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          {isLoading ? (
            <button className="refresh-button">טוען...</button>
          ) : (
            <button className="refresh-button" onClick={fetchTestResults}>
              רענן
            </button>
          )}
        </div>
      </div>
      {renderTable(tests)}
    </div>
  );
};

export default TestResults;

import React, { useState, useEffect } from 'react';
import '../styles/ShooterLogForm.css';

const ShooterLogForm = ({ isOpen, onClose, onSave, initialData = {} }) => {
  const [formData, setFormData] = useState({
    rangeId: '',
    registrationDate: '',
    fullName: '',
    teudatZehut: '',
    phoneNumber: '',
    registrationType: '',
    healthDeclarationConfirm: false,
    licenseValidity: '',
    pistolManufacturer: '',
    imprintOnPistol: '',
    ammunitionCaliber: '',
    bulletCount: '',
    trainingEndTime: '',
    verifiedBy: '',
    managedBy: '',
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="form-popup">
      <div className="form-container">
        <h2>{initialData ? 'ערוך יומן' : 'הוסף ליומן'}</h2>
        <form onSubmit={handleSubmit}>
          {/* Row 1 */}
          <label>
            מספר:
            <input
              type="text"
              name="rangeId"
              value={formData.rangeId}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            כניסה לאימון:
            <input
              type="text"
              name="registrationDate"
              value={formData.registrationDate}
              onChange={handleChange}
              required
            />
          </label>
          
          {/* Row 2 */}
          <label>
            שם ומשפחה:
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            תז:
            <input
              type="text"
              name="teudatZehut"
              value={formData.teudatZehut}
              onChange={handleChange}
              required
            />
          </label>
  
          {/* Row 3 */}
          <label>
            נייד:
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            סוג אימון:
            <select className='register-select' name="registrationType" value={formData.registrationType} onChange={handleChange} required>
                <option value="חידוש רישיון">חידוש רישיון</option>
                <option value="רענון תקופתי">רענון תקופתי</option>
                <option value="ירי חופשי">ירי השתפרות - "חופשי"</option>
                <option value="הכשרה ראשונית">הכשרה ראשונית</option>
                <option value="הכשרה ארגונית">הכשרה ארגונית</option>
                <option value="בדיקת תקינות נשק">בדיקת תקינות נשק</option>
              </select>
          </label>
  
          {/* Row 4 */}
          <label className="checkbox-group">
            הצהרת בריאות:
            <input
              type="checkbox"
              name="healthDeclarationConfirm"
              checked={formData.healthDeclarationConfirm}
              onChange={handleChange}
            />
          </label>
          <label>
            תוקף רישיון:
            <input
              type="text"
              name="licenseValidity"
              value={formData.licenseValidity}
              onChange={handleChange}
            />
          </label>
  
          {/* Row 5 */}
          <label>
            יצרן האקדח:
            <select className='register-select' name="pistolManufacturer" value={formData.pistolManufacturer} onChange={handleChange} required>
                <option value="none">בחר יצרן</option>
                <option value="Glock">Glock - גלוק</option>
                <option value="Sig Sauer">Sig Sauer - סיג סאוור</option>
                <option value="IWI">IWI</option>
                <option value="Smith & Wesson">Smith & Wesson - סמית' אנד ווסון</option>
                <option value="CZ">CZ - סי זד</option>
                <option value="Beretta">Beretta - ברטה</option>
                <option value="Karin">Karin - קארין</option>
                <option value="Walther">Walther - וולטר</option>
                <option value="BUL">BUL - בול</option>
                <option value="FN">FN - בראונינג</option>
                <option value="Ruger">Ruger - רוגר</option>
                <option value="Springfield">Springfield - ספרינגפילד</option>
                <option value="Shadow System">Shadow Systems - שאדו סיסטמס</option>
                <option value="Colt">Colt - קולט</option>
                <option value="Mossberg">Mossberg - מוסברג</option>
                <option value="HELLCAT">HELLCAT - הלקט</option>
                <option value="Taurus">Taurus - טאורוס</option>
                <option value="Ramon">Ramon - רמון</option>
                <option value="Other">אחר</option>
              </select>
            </label>
          {/* Conditionally render the input field if "Other" is selected */}
          {formData.pistolManufacturer === 'Other' && (
            <label>
              <input
                className='register-input'
                type="text"
                name="otherOption"
                value={formData.otherOption}
                onChange={handleChange}
                placeholder="הכנסה ידנית של יצרן"
                required
              />
            </label>
          )}

          <label>
            מספר כלי:
            <input
              type="text"
              name="imprintOnPistol"
              value={formData.imprintOnPistol}
              onChange={handleChange}
            />
          </label>
  
          {/* Row 6 */}
          <label>
            קליבר:
            <select className='register-select' name="ammunitionCaliber" value={formData.ammunitionCaliber} onChange={(e) => handleChange('ammunitionCaliber', e.target.value)}>
              <option value="none"> בחר</option>
              <option value='9x19'>9x19</option>
              <option value='9x17'>9x17</option>
              <option value='7.65browning'>7.65 Browning</option>
              <option value='22LR'>22LR</option>
              <option value='22WM'>22WM</option>
              <option value='45AUTO'>45AUTO</option>
              <option value='44MAG'>44MAG</option>
              <option value='20S&W'>20S&W</option>
              <option value='22SHORT'>22SHORT</option>
              <option value='6.35'>6.35</option>
              <option value='38SPL'>38SPL</option>
              <option value='222REM'>222REM</option>
              <option value='22HORNET'>22HORNET</option>
              <option value='50AE'>50AE</option>
              <option value='32S&WLONG'>32S&WLONG</option>
              <option value='357MAG'>357MAG</option>
              <option value='38S&W'>38S&W</option>
              <option value='41AE'>41AE</option>
              <option value='7.63x25'>7.63x25</option>
              <option value='12Gauge'>12Gauge</option>
              <option value='5.56x45'>5.56x45</option>
            </select>
          </label>
          <label>
            כמות:
            <input
              type="text"
              name="bulletCount"
              value={formData.bulletCount}
              onChange={handleChange}
            />
          </label>
  
          {/* Row 7 */}
          <label>
            שעת סיום האימון:
            <input
              type="text"
              name="trainingEndTime"
              value={formData.trainingEndTime}
              onChange={handleChange}
            />
          </label>
          <label>
            חתימת המאמן:
            <select value={formData.verifiedBy} onChange={(e) => handleChange('verifiedBy', e.target.value)}>
              <option value="שי לוי">שי לוי</option>
              <option value="אלכסנדר אודיניץ">אלכסנדר אודיניץ</option>
              <option value="בן אברהם">בן אברהם</option>
              <option value="טל ביאלוסטוצקי">טל ביאלוסטוצקי</option>
              <option value="ישי זולדן">ישי זולדן</option>
              <option value="אלכסנדר פינקוביסקי">אלכסנדר פינקוביסקי</option>
            </select>
          </label>
  
          {/* Row 8 */}
          <label className="full-width">
            חתימת המדריך:
            <select value={formData.verifiedBy} onChange={(e) => handleChange('managedBy', e.target.value)}>
              <option value="שי לוי">שי לוי</option>
              <option value="אלכסנדר אודיניץ">אלכסנדר אודיניץ</option>
              <option value="בן אברהם">בן אברהם</option>
              <option value="טל ביאלוסטוצקי">טל ביאלוסטוצקי</option>
              <option value="ישי זולדן">ישי זולדן</option>
              <option value="אלכסנדר פינקוביסקי">אלכסנדר פינקוביסקי</option>
            </select>
          </label>
  
          {/* Buttons */}
          <div className="button-group">
            <button type="button" className="minimize-button" onClick={onClose}>סגור</button>
            <button type="submit" className="save-button">{initialData ? 'עדכון' : 'הוסף'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShooterLogForm;

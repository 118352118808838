// ProgressBar.js
import React from 'react';
import '../styles/ProgressBar.css';

const ProgressBar = ({ step }) => {
  const steps = 4;
  const progress = (step / steps) * 100;

  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      <div className="progress-labels">
        <span className={step >= 1 ? 'active' : ''}>1</span>
        <span className={step >= 2 ? 'active' : ''}>2</span>
        <span className={step >= 3 ? 'active' : ''}>3</span>
        <span className={step >= 4 ? 'active' : ''}>4</span>
      </div>
    </div>
  );
};

export default ProgressBar;

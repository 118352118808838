import React, { useState } from 'react';
import '../styles/Footer.css';
import insta_icon from '../icons/icons8-instagram-100.png';
import facebook_icon from '../icons/icons8-facebook-100.png';
import waze_icon from '../icons/icons8-waze-100.png';
import youtube_icon from '../icons/icons8-youtube-100.png';
import call_icon from '../icons/icons8-call-100.png';
import whatsapp_icon from '../icons/icons8-whatsapp-100.png';
import gun_icon from '../icons/icons8-gun-100.png';
import close_icon from '../icons/icons8-close-100.png';
import { useLanguage } from '../contexts/LanguageContext';
import Subscribe from './Subscribe';
import text from '../text';

const Footer = ({ onLogin , onShowWelcome }) => {
  const { language } = useLanguage();
  const [isCallPopupVisible, setIsCallPopupVisible] = useState(false);
  const [isNewsletterPopupVisible, setIsNewsletterPopupVisible] = useState(false);

  const handleShowWelcome = () => {
    toggleCallPopup();
    onShowWelcome();
  };

  const toggleCallPopup = () => {
    setIsCallPopupVisible(!isCallPopupVisible);
  };

  const toggleNewsletterPopup = () => {
    setIsNewsletterPopupVisible(!isNewsletterPopupVisible);
  };

  return (
    <div className="footer">
      <div className="footer-address">
        <p>{text[language].footerAddress}</p>
        <p>{text[language].address}</p>
        <p>{text[language].footerPhone}</p>
      </div>
      <div className="footer-social" style={{alignItems:"end"}}>
        <small>Powered by OL - V2.1.5 </small>
      </div>
      <div className="footer-social">
        <img src={call_icon} alt="Call" className="call-icon" onClick={toggleCallPopup} />
        <a
          href="https://ul.waze.com/ul?place=ChIJ9y8Y5zcjHRURYU7SkneikiA&ll=32.17155300%2C35.09746200&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={waze_icon} alt="Waze" />
        </a>
        <a
          href="https://www.facebook.com/p/%D7%9E%D7%98%D7%95%D7%95%D7%97-%D7%A9%D7%99-%D7%9C%D7%95%D7%99-%D7%A7%D7%A8%D7%A0%D7%99-%D7%A9%D7%95%D7%9E%D7%A8%D7%95%D7%9F--100063611302763/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook_icon} alt="Facebook" />
        </a>
        <a href="https://www.youtube.com/@KBMShaiLevi" target="_blank" rel="noopener noreferrer">
          <img src={youtube_icon} alt="YouTube" />
        </a>
        <a href="https://www.instagram.com/shai_levi_k.b.m/" target="_blank" rel="noopener noreferrer">
          <img src={insta_icon} alt="Instagram" />
        </a>
        <a href="https://wa.me/972585914713" target="_blank" rel="noopener noreferrer">
          <img src={whatsapp_icon} alt="WhatsApp" />
        </a>
        <img src={gun_icon} alt="Register" onClick={toggleNewsletterPopup} />
      </div>
      {isCallPopupVisible && (
        <div className="popup-overlay" onClick={toggleCallPopup}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <img src={close_icon} alt="Close" className="close-icon" onClick={toggleCallPopup} />
            <br />
            <h3>{text[language].beforeCall}</h3>
            <button className='popup-buttons' onClick={handleShowWelcome}>{text[language].trainingHours}</button>
            <button className='popup-buttons'>
              <a href="https://ul.waze.com/ul?place=ChIJ9y8Y5zcjHRURYU7SkneikiA&ll=32.17155300%2C35.09746200&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
              target="_blank"
              rel="noopener noreferrer">{text[language].directions}
              </a>
              </button>
            <button className='popup-buttons'>
              <a href="https://wa.me/972585914713" target="_blank" rel="noopener noreferrer">
              {text[language].whatsappUs}
              </a>
            </button>
            <button className='popup-buttons' onClick={() => window.location.href = 'tel:058-591-4713'}>{text[language].callUs}
            </button>
          </div>
        </div>
      )}
      {isNewsletterPopupVisible && (
        <Subscribe onClose={toggleNewsletterPopup} />
      )}
    </div>
  );
};

export default Footer;

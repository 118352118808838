import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import '../styles/NewLicense.css';
import { logEvent, analytics, db } from './firebase';
import { collection, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import youtubeIcon from '../icons/icons8-youtube-100.png';
import { Helmet } from 'react-helmet';

const NewLicenseContent = () => {
  const { language } = useLanguage();
  const CHANNEL_ID = 'UC-sW4_BaoZG8XHPpUwAMyPg';

  const updateCounter = async (event) => {
    const eventRef = doc(collection(db, 'analytics'), event);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      await updateDoc(eventRef, { counter: increment(1) });
    } else {
      await setDoc(eventRef, { event, counter: 1, timestamp: new Date() });
    }
  };

  const handleYoutubeClick = async () => {
    logEvent(analytics, 'youtube_channel_visited');
    await updateCounter('youtube_channel_visited');
  };

  return (
    <div className="new-license-content">
      <Helmet>
        <title>מטווח שי לוי - מדריך להוצאת רישיון חדש</title>
        <meta name="description" content="מטווח שי לוי - קרני שומרון, ביקורות אקדחים, אימונים, חידוש רישיון ועוד" />
        <meta name="keywords" content=", נרתיק לאקדח, זיג, Sig Sauer, הלקט , אקדח חדש, אקדח משומש דיקת תיקנות נשק, רענון תקופתי, מטווח , מטווח שי לוי , הכשרה ראשונית , רישיון לאקדח, גלוק, Glock, מאבטחים, חידוש רישיון, קרני שומרון" />
      </Helmet>
      <h2>{text[language].newLicenseTitle2}</h2>
      <p>{text[language].licenseExplanation}</p>
      
      <ol>
        <li>
          {text[language].healthDeclarationInfo}
          <a href="https://www.gov.il/BlobFolder/service/issue_firearms_license_to_a_private_individual/en/fa_health_dec.pdf" target="_blank" rel="noopener noreferrer"> {text[language].downloadForm}</a>
        </li>
        <li>{text[language].idWithAppendix}</li>
        <li>{text[language].militaryApproval}</li>
        <li>
          {text[language].eligibilityCriteria}
          <br />
          {text[language].eligibilityExamples}
          <ul>
            <li>{text[language].eligibleSettlement}</li>
            <li>{text[language].combatCertificate}</li>
            <li>{text[language].officerCertificate}</li>
            <li>
              <a href="https://ishurim.prat.idf.il/Account/Login?ReturnUrl=%2f%3fAspxAutoDetectCookieSupport%3d1&AspxAutoDetectCookieSupport=1" target="_blank" rel="noopener noreferrer">{text[language].idfApprovalLink}</a>
            </li>
            <li>
              <a href="https://www.gov.il/apps/mops/firearm_license_calculator/" target="_blank" rel="noopener noreferrer">{text[language].eligibilityCalculator}</a>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        {text[language].scanAndSubmit}
        <br />
        <a href="https://www.gov.il/he/service/issue_firearms_license_to_a_private_individual" target="_blank" rel="noopener noreferrer">{text[language].ministryLink}</a>
      </p>
      <h3>{text[language].afterInterview}</h3>
      <ol>
        <li>{text[language].conditionalApprovalReceived}</li>
        <li>{text[language].visitShootingRange}</li>
        <li>{text[language].chooseGun}</li>
        <li>{text[language].trainingSession}</li>
        <li>{text[language].documentsSubmission}</li>
        <li>{text[language].getTemporaryLicense}</li>
        <li>{text[language].collectGun}</li>
      </ol>
      <p className="video-section">
        {text[language].youtubeVideo}
        <br />
        <iframe width="560" height="315" src="https://www.youtube.com/embed/d9ouDZ1hX0Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </p>
      <div className="youtube-banner">
        <a href={`https://www.youtube.com/channel/${CHANNEL_ID}?sub_confirmation=1`}
          target="_blank" 
          rel="noopener noreferrer"
          onClick={handleYoutubeClick}>
          <p>{text[language].visitYoutubeChannel}</p>
          <img src={youtubeIcon} alt="YouTube" className="youtube-icon" />
        </a>
      </div>
    </div>
  );
};

export default NewLicenseContent;

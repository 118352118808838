import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import generateAmmoSale from '../FileGenerators/AmmoGen';
import generateGunAmmoSale from '../FileGenerators/gunAndAmmoGen';
import generateCertificate from '../FileGenerators/LocalTrainCertificateGen';
import '../styles/ClientManagementPopup.css';
import { logError } from '../components/firebase.js';
import { error } from 'pdf-lib';


const GunAmmoTrain = ({
  formData,
  setFormData,
  showGunForm,
  setShowGunForm,
  showAmmoForm,
  setShowAmmoForm,
  showCertificateForm,
  setShowCertificateForm,
}) => {
  const sigCanvas = useRef({});
  const [caliber, setCaliber] = useState(formData.ammunitionCaliber ||formData.caliber || '');
  const [manufacturer, setManufacturer] = useState(formData.pistolManufacturer || formData.manufacturer || '');
  const [imprintOnPistol, setImprintOnPistol] = useState(formData.imprintOnPistol || '');
  const [model, setModel] = useState(formData.model || '');
  const [origin, setOrigin] = useState(formData.origin || '');
  const [hits, setHits] = useState(formData.hits || 0);
  const [isLoading, setIsLoading] = useState(false);

  

  
  const handleGenerateGun = async () => {
    setIsLoading(true);
    
    
    let signatureUrl = null;
    if (sigCanvas.current && typeof sigCanvas.current.toDataURL === 'function') {
      signatureUrl = sigCanvas.current.toDataURL('image/png');
    } else {
      console.error('Signature pad is not available');
      logError(error,formData.teudatZehut,"GunAmmoTrain.js - handleGenerateGun");
      return;
    }

    const updatedFormData = {
      ...formData,
      caliber,
      manufacturer,
      model,
      origin,
      bulletCount: formData.bulletCount ,
      signatureUrl,
    };

    const pdfUrl = await generateGunAmmoSale(updatedFormData);
    setFormData({ ...formData, gunTransferUrl: pdfUrl });
    setShowGunForm(false);
    setIsLoading(false);
  };

  const handleGenerateAmmoSale = async () => {
    setIsLoading(true);
    
    
    let signatureUrl = null;
    if (sigCanvas.current && typeof sigCanvas.current.toDataURL === 'function') {
      signatureUrl = sigCanvas.current.toDataURL('image/png');
    } else {
      console.error('Signature pad is not available');
      logError(error,formData.teudatZehut,"GunAmmoTrain.js - handleGenerateAmmoSale");
      return;
    }

    const updatedFormData = {
      ...formData,
      caliber,
      signatureUrl,
      bulletCount: formData.bulletCount ,
    };

    const pdfUrl = await generateAmmoSale(updatedFormData);
    setFormData({ ...formData, ammoTransferUrl: pdfUrl });
    setShowAmmoForm(false);
    setIsLoading(false);
  };

  const handleGenerateTrainConfirmation = async () => {
    
    
    setIsLoading(true);
    let signatureUrl = null;
    if (sigCanvas.current && typeof sigCanvas.current.toDataURL === 'function') {
      signatureUrl = sigCanvas.current.toDataURL('image/png');
    } else {
      console.error('Signature pad is not available');
      logError(error,formData.teudatZehut,"GunAmmoTrain.js - handleGenerateTrainConfirmation");
    }

    const updatedFormData = {
      ...formData,
      signatureUrl,
      caliber,
      manufacturer,
      model,
      hits,
      bulletCount: formData.bulletCount ,
    };

    const pdfUrl = await generateCertificate(updatedFormData);
    setFormData({ ...formData, trainConfirmationUrl: pdfUrl });
    setShowCertificateForm(false);
    setIsLoading(false);
  };

  return (
      <div>
      {isLoading ? (
          <button className="loading-spinner-small" ></button>
        ) : ( 
            <>
      {showGunForm && (
        <div className="client-detail">
          <h3>שטר מכר - העברת בעלות אקדח ותחמושת</h3>
          <small>בן - אם עדכנת בפרטי לקוח את הפרטים שלו ולא מופיע בשדות הבאים - שמור את הכרטיס ותפתח מחדש</small>
          <p>יש לעדכן את הנתונים הבאים - להחתים את המוכר</p>
          <div className='details-col'>
            <label>
              יצרן:
              <input
                type="text"
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
                />
            </label>
            <label>
            <label>
              דגם - באנגלית בלבד:
              <input
                type="text"
                value={model}
                onChange={(e) => setModel(e.target.value)}
              />
            </label>
            <label>
              ארץ ייצור - בעברית בלבד
              <input
                type="text"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
              />
            </label>
            <label>
              מספר כלי :
              <input
                type="text"
                value={imprintOnPistol}
                onChange={(e) => setImprintOnPistol(e.target.value)}
              />
            </label>
              קוטר קליע:
              <input
                type="text"
                value={caliber}
                onChange={(e) => setCaliber(e.target.value)}
              />
            </label>
            <label>
              כמות כדורים:
              <input
                type="number"
                value={formData.bulletCount}
                onChange={(e) => setFormData({ ...formData, bulletCount: e.target.value })}
              />
            </label>
          </div>
          <div className="details-col">
            <div>
              <p>חתימת הלקוח : </p>
              <SignatureCanvas
                penColor="#3c6eb4" 
                ref={sigCanvas}
                canvasProps={{className: 'sigCanvas' }}
              />
            </div>
          </div>
          <button className='show-button' onClick={() => sigCanvas.current.clear()}>נקה חתימה</button>
          <button className='show-button' onClick={handleGenerateGun}>צור שטר מכר</button>
          <button className='show-button' onClick={() => setShowGunForm(false)}>ביטול</button>
        </div>
      )}

      {showAmmoForm && (
        <div className="client-detail">
          <h3>שטר מכר - העברת בעלות תחמושת</h3>
          <small>בן - אם עדכנת בפרטי לקוח את הפרטים שלו ולא מופיע בשדות הבאים - שמור את הכרטיס ותפתח מחדש</small>
          <p>יש לעדכן כמות תחמושת וקוטר קליע התחמושת הנמכרת - להחתים את הלקוח</p>
          <div className='details-col'>
            <label>
              קוטר קליע:
              <input
                type="text"
                value={caliber}
                onChange={(e) => setCaliber(e.target.value)}
              />
            </label>
            <label>
              כמות כדורים:
              <input
                type="number"
                value={formData.bulletCount}
                onChange={(e) => setFormData({ ...formData, bulletCount: e.target.value })}
              />
            </label>
          </div>
          <div className="details-col">
            <div>
              <p>חתימת הלקוח : </p>
              <SignatureCanvas
                penColor="#3c6eb4" 
                ref={sigCanvas}
                canvasProps={{className: 'sigCanvas' }}
              />
            </div>
          </div>
          <button className='show-button' onClick={() => sigCanvas.current.clear()}>נקה חתימה</button>
          <button className='show-button' onClick={handleGenerateAmmoSale}>צור שטר מכר</button>
          <button className='show-button' onClick={() => setShowAmmoForm(false)}>ביטול</button>
        </div>
      )}

      {showCertificateForm && (
        <div className="client-detail">
          <h3>אישור הדרכה במטווח לצורך הוצאת רישיון</h3>
          <small>בן - אם עדכנת בפרטי לקוח את הפרטים שלו ולא מופיע בשדות הבאים - שמור את הכרטיס ותפתח מחדש</small>
          <p>יש להחתים את הלקוח על תעודת ההדרכה</p>
          <div className="details-col">
            <label>
              יצרן - באנגלית בלבד: (מומלץ לשנות בפרטי לקוח)
              <input
                type="text"
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
              />
            </label>
            <label>
              דגם - באנגלית בלבד:
              <input
                type="text"
                value={model}
                onChange={(e) => setModel(e.target.value)}
              />
            </label>
            <label>
              קוטר קליע:
              <input
                type="text"
                value={caliber}
                onChange={(e) => setCaliber(e.target.value)}
              />
            </label>
            <label>
              כמות כדורים:
              <input
                type="number"
                value={formData.bulletCount}
                onChange={(e) => setFormData({ ...formData, bulletCount: e.target.value })}
              />
            </label>
            <label>
              כמות פגיעות מתוך 20 כדורים:
              <input
                type="number"
                value={hits}
                onChange={(e) => setHits(e.target.value)}
              />
            </label>
            <p> יש לחתום ידנית על המסמך ע"י מדריך המטווח</p>
          </div>
          
          <button className='show-button' onClick={() => sigCanvas.current.clear()}>נקה חתימה</button>
          <button className='show-button' onClick={handleGenerateTrainConfirmation}>צור תעודת הדרכה</button>
          <button className='show-button' onClick={() => setShowCertificateForm(false)}>ביטול</button>
        </div>
      )}
        </>
        )}

    </div>
    
);
};

export default GunAmmoTrain;

// Modal.js
import React from 'react';
import '../styles/Modal.css';

const Modal = ({ isOpen, onClose, onConfirm, title, message }) => {
  if (!isOpen) return null; // Do not render if the modal is not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="modal-buttons">
          <button className="modal-button" onClick={onConfirm}>מעבר לאתר המטווח</button> {/* OK Button */}
        </div>
      </div>
    </div>
  );
};

export default Modal;
